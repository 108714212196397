import React, { useState } from 'react';
import { Container, Row, Col, Input, Button, Label } from 'reactstrap';

const Home = (props) => {
  const [userObj, setUserObj] = useState(localStorage.getItem('userObj') != null ? JSON.parse(localStorage.getItem('userObj')) : {});
  const [error, setError] = useState(null);
  const [username, setUsername] = useState(localStorage.getItem('rememberUsername') != null ? localStorage.getItem('rememberUsername') : '')
  const [password, setPassword] = useState('');
  const [rememberMeOnThisDevice, setRememberMeOnThisDevice] = useState(localStorage.getItem('rememberUsername') != null ? true : false);

  const login = async () => {
    if (rememberMeOnThisDevice) {
      localStorage.setItem('rememberUsername', username);
    } else {
      localStorage.removeItem('rememberUsername');
    };
    const response = await fetch('/app/login/', {
      method: 'POST',
      body: JSON.stringify({
        username: username,
        password: password,
      }),
      headers: {
        'Content-Type': 'application/json',
      }
    })
    if (response.ok) {
      const results = await response.json();
      props.loginUser(results);
      setError(null);
      if (results.accessToken) {
        setUserObj(results);
        localStorage.setItem('userObj', JSON.stringify(results));
      }
    } else {
      setError('Unable to Login');
    }

  }

  return (
    <Container fluid className='tablet p-0 m-0'>
      {userObj != null && userObj.accessToken != null && userObj.accessToken.length === 255 ?
        <>
          <Row className='m-0'>
            <Col>
              <ul className='list-unstyled'>
                {props.links.map(link => (
                  <li key={link.url} className='my-3'><a className='text-primary text-decoration-none fw-bold' href={link.url}>{link.link}</a></li>
                ))}
                <li><a className='text-primary text-decoration-none fw-bold pointer' onClick={props.logout}>Logout</a></li>
              </ul>
            </Col>
          </Row>
        </>
        :
        <Row>
          <Col md={{ size: 6, offset: 3 }}>
            <Row className='mt-3'>
              <Col>
                <img src="/logo-asta-construction.png" className='w-100 my-4' alt="ASTA Construction" />
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col><Label>Email</Label>
                <Input
                  // name="teamMemberUsername"
                  data-lpignore="true"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col><Label>Password</Label>
                <Input
                  // name="teamMemberPassword"
                  data-lpignore="true"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Col>
            </Row>
            <Row className='mt-5'>
              <Col><Label>
                <Input
                  type="checkbox"
                  className='me-2'
                  checked={rememberMeOnThisDevice}
                  onChange={(e) => setRememberMeOnThisDevice(!rememberMeOnThisDevice)}
                />Remember my username on this device</Label>
              </Col>
            </Row>
            <Row className='mt-5'>
              <Col>
                <Button
                  color="primary"
                  size="lg"
                  block
                  disabled={username === '' || password === ''}
                  name="login"
                  onClick={login}
                >Login</Button>
              </Col>
            </Row>
            {error != null && <Row className='mt-4 text-danger text-center font-weight-bold'>
              <Col>
                {error}
              </Col>
            </Row>}
          </Col>
        </Row>
      }
    </Container>
  );
};

export default Home;
