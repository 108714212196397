import React, { Fragment, useEffect, useState, useContext } from 'react';
import { Row, Col, Table, Input, Button, Label, FormGroup } from 'reactstrap';
import ConfirmButton from '../components/ConfirmButton/ConfirmButton';
import NavContext from '../components/Nav/NavContext';
import * as utilities from '../utilities';

const Users = (props) => {

    const blankUser = {
        userGUID: '',
        firstName: '',
        lastName: '',
        description: '',
        username: '',
        password: '',
        active: true
    }

    const [users, setUsers] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [editUserGUID, setEditUserGUID] = useState(null);
    const [editUser, setEditUser] = useState(blankUser);

    const { setNavContent } = useContext(NavContext);

    useEffect(() => {
        setNavContent(
            <h2 className='text-end text-light'>Users</h2>
        );
        return () => setNavContent(null);
    }, [setNavContent]);

    const getUsers = async () => {
        const response = await fetch('/api/users/', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + utilities.getAccessToken()
            }
        })
        if (response.ok) {
            const result = await response.json();
            setUsers(result);
        } else {
            utilities.addToast('Unable to load users', 5, 'danger');
        }
    };

    const getPermissions = async () => {
        const response = await fetch('/api/permissions/', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + utilities.getAccessToken()
            }
        })
        if (response.ok) {
            const result = await response.json();
            setPermissions(result);
        }
    };

    const handleDeleteUser = async () => {
        const response = await fetch('/api/users/' + editUserGUID, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + utilities.getAccessToken()
            }
        })
        if (response.ok) {
            utilities.addToast('User deleted', 5, 'success');
            setEditUserGUID(null);
            setEditUser(blankUser);
            getUsers();
        } else {
            utilities.addToast('Unable to delete user', 5, 'danger');
        }
    };

    const handleSave = async () => {
        const response = await fetch('/api/users/' + editUserGUID, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + utilities.getAccessToken()
            },
            body: JSON.stringify(editUser)
        })
        if (response.ok) {
            utilities.addToast('User updated', 5, 'success');
            setEditUserGUID(null);
            setEditUser(blankUser);
            getUsers();
        } else {
            utilities.addToast('Unable to update user', 5, 'danger');
        }
    }

    const handleAdd = async () => {
        const response = await fetch('/api/users/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + utilities.getAccessToken()
            },
            body: JSON.stringify(blankUser)
        })
        if (response.ok) {
            const result = await response.text();
            setEditUserGUID(result);
            setEditUser(blankUser);
            getUsers();
        }
    }

    const onEditUser = (userGUID) => {
        const userIndex = users.findIndex(user => user.userGUID === userGUID);
        if (userIndex === -1) return;
        setEditUser({
            userGUID: userGUID,
            firstName: users[userIndex].firstName,
            lastName: users[userIndex].lastName,
            description: users[userIndex].description,
            username: users[userIndex].username,
            password: users[userIndex].password,
            active: users[userIndex].active === 1,
            permissions: users[userIndex].permissions
        });
        setEditUserGUID(userGUID);
    }

    const handleChangePermission = (permission) => {
        let tempEditUser = { ...editUser };
        if (tempEditUser.permissions == null || tempEditUser.permissions.length === 0) tempEditUser.permissions = [];
        const userPermissionIndex = tempEditUser.permissions.findIndex(p => p.permissionGUID === permission.permissionGUID);
        let permissionsArray = []
        if (userPermissionIndex === -1) {
            permissionsArray = tempEditUser.permissions.concat({ permission: permission.permission, permissionGUID: permission.permissionGUID })
        } else {
            permissionsArray = tempEditUser.permissions;
            permissionsArray.splice(userPermissionIndex, 1);
        }
        tempEditUser.permissions = permissionsArray
        setEditUser(tempEditUser)
    }

    useEffect(() => {
        getUsers();
        getPermissions();
    }, []);

    return (
        <>
            {<div className='tablet p-0 m-0'>

                <Row className='mt-3'>
                    <Col>
                        <Table className='table-white'>
                            <thead>
                                <tr>
                                    <th className='py-4' width="15%">User</th>
                                    <th className='py-4' width="20%">Username</th>
                                    <th className='py-4' width="20%">Description</th>
                                    <th className='py-4' width="35%">Permissions</th>
                                    <th className='py-4' width="10%">Active</th>
                                </tr>
                            </thead>
                            <tbody>
                                {users.map((user, userIndex) => (<Fragment key={userIndex}>
                                    {user.userGUID === editUserGUID ?
                                        <tr>
                                            <td colSpan={5}>
                                                <Row className='row-cols-lg-auto g-2 my-2 align-items-end'>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>
                                                                First Name
                                                                <Input
                                                                    name="firstName"
                                                                    value={editUser.firstName}
                                                                    onChange={(e) => setEditUser({ ...editUser, [e.target.name]: e.target.value })}
                                                                />
                                                            </Label>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>
                                                                Last Name
                                                                <Input
                                                                    name="lastName"
                                                                    value={editUser.lastName}
                                                                    onChange={(e) => setEditUser({ ...editUser, [e.target.name]: e.target.value })}
                                                                />
                                                            </Label>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>
                                                                Description
                                                                <Input
                                                                    name="description"
                                                                    value={editUser.description}
                                                                    onChange={(e) => setEditUser({ ...editUser, [e.target.name]: e.target.value })}
                                                                />
                                                            </Label>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>
                                                                Username
                                                                <Input
                                                                    name="username"
                                                                    value={editUser.username}
                                                                    onChange={(e) => setEditUser({ ...editUser, [e.target.name]: e.target.value })}
                                                                />
                                                            </Label>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>
                                                                Password
                                                                <Input
                                                                    name="password"
                                                                    type="password"
                                                                    value={editUser.password}
                                                                    onChange={(e) => setEditUser({ ...editUser, [e.target.name]: e.target.value })}
                                                                />
                                                            </Label>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>
                                                                <Input
                                                                    name="active"
                                                                    type="checkbox"
                                                                    className='me-2'
                                                                    checked={editUser.active}
                                                                    onChange={(e) => setEditUser({ ...editUser, [e.target.name]: !editUser[e.target.name] })}
                                                                />
                                                                Active
                                                            </Label>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row className='align-items-end py-3'>
                                                    <Col sm={6}>
                                                        <ul className='list-unstyled'>
                                                            {permissions.map(permission => (
                                                                <li key={permission.permissionGUID}><Label>
                                                                    <Input
                                                                        type="checkbox"
                                                                        className='mx-2 mt-0 mb-2 p-0'
                                                                        checked={editUser.permissions != null && editUser.permissions.length > 0 ? editUser.permissions.findIndex(p => p.permissionGUID === permission.permissionGUID) > -1 : false}
                                                                        onChange={() => handleChangePermission(permission)}
                                                                    >
                                                                    </Input>{permission.permission}
                                                                </Label>
                                                                </li>
                                                            )
                                                            )}
                                                        </ul>
                                                    </Col>
                                                    <Col className='text-end pe-4'>
                                                        <Button
                                                            color="secondary"
                                                            className='ms-2'
                                                            onClick={() => {
                                                                setEditUser(blankUser);
                                                                setEditUserGUID(null)
                                                            }}
                                                        >
                                                            Cancel
                                                        </Button>
                                                        <ConfirmButton
                                                            color="danger"
                                                            className='mx-4'
                                                            onClick={handleDeleteUser}
                                                        >
                                                            Delete
                                                        </ConfirmButton>
                                                        <Button
                                                            color="success"
                                                            className='ms-2'
                                                            onClick={handleSave}
                                                        >
                                                            Save
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </td>
                                        </tr>
                                        : <tr key={user.userGUID} className='pointer' onClick={() => onEditUser(user.userGUID)}>
                                            <td className='py-3'>{user.lastName}, {user.firstName}</td>
                                            <td className='py-3'>{user.username}</td>
                                            <td className='py-3'>{user.description}</td>
                                            <td className='py-3 smallText'>{user.permissions != null ? [...user.permissions].map(permission => permission.permission).join(', ') : ''}</td>
                                            <td className='py-3'>{user.active === 1 ? "Yes" : "No"}</td>
                                        </tr>}
                                </Fragment>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button
                            color="primary"
                            className='ms-2'
                            onClick={handleAdd}
                        >
                            Add User
                        </Button>
                    </Col>
                </Row>
            </div >}
        </>
    );
};

export default Users;
