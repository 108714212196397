import React, { useState, useRef, useCallback } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { ReactSketchCanvas } from 'react-sketch-canvas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const styles = {
    border: '0.0625rem solid #9c9c9c',
    borderRadius: '0.25rem',
};

const CanvasModal = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [paths, setPaths] = useState([]);

    // Use useRef to keep canvasRef consistent across re-renders
    const canvasRef = useRef();

    // Use useCallback to memoize the function and ensure consistency
    const setCanvasRef = useCallback((ref) => {
        if (ref) {
            canvasRef.current = ref;
        }
    }, []);

    const showModal = (isOpen) => {
        setIsOpen(isOpen);
    };

    const saveImage = () => {
        showModal(false);
        if (canvasRef.current) {
            canvasRef.current
                .exportPaths()
                .then((paths) => {
                    setPaths(paths);
                    // props.savePaths(paths);
                    if (paths.length > 0) {
                        canvasRef.current.exportImage('png')
                            .then((data) => {
                                props.saveimage((prev) => {
                                    let obj = { ...prev }
                                    obj[props.config][props.arrkey] = data;
                                    return obj;
                                });
                            })
                            .catch((e) => {
                                console.log(e);
                            });
                    } else {
                        props.saveimage((prev) => {
                            let obj = { ...prev }
                            obj[props.config][props.arrkey] = '';
                            return obj;
                        });
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
    };

    const clearImage = () => {
        if (canvasRef.current) {
            canvasRef.current.clearCanvas();
        }
    };

    return (
        <>
            {!props.onChange && <div onClick={() => setIsOpen(true)}>
                {props.children}
            </div>}
            {props.onChange && <div onChange={(e) => {
                if (e.target.value > 0) setIsOpen(true)
            }}>
                {props.children}
            </div>}
            <Modal centered isOpen={isOpen} size="lg">
                <ModalHeader
                    onClick={() => showModal(false)}
                    close={
                        <Button className='faButton' color="light">
                            <FontAwesomeIcon icon={faTimes} />
                        </Button>
                    }
                >
                    Draw Signature
                </ModalHeader>
                <ModalBody className="text-center">
                    <ReactSketchCanvas
                        style={styles}
                        ref={(ref) => setCanvasRef(ref)}
                        width="900"
                        height="300"
                        strokeWidth={8}
                        strokeColor="black"
                    // loadPaths={paths}
                    />
                </ModalBody>
                <ModalFooter className="justify-content-between">
                    <Button color="primary" onClick={clearImage}>
                        Clear
                    </Button>
                    <Button color="success" onClick={saveImage}>
                        Save
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default CanvasModal;
