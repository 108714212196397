import React, { useEffect, useRef, useState, useContext } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { JSONEditor } from '@json-editor/json-editor';
import '@json-editor/json-editor/src/style.css';
import NavContext from '../components/Nav/NavContext';
import * as utilities from '../utilities';

const Config = (props) => {

  const editorRef = useRef(null);
  const [resultConfig, setResultConfig] = useState('{}');
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const resultConfigRef = useRef(resultConfig);
  resultConfigRef.current = resultConfig;

  const { setNavContent } = useContext(NavContext);

  useEffect(() => {
    setNavContent(
      <Button
        color="success"
        className='mb-1'
        disabled={isSaving}
        onClick={() => {
          setIsSaving(true);
          saveConfig()
        }}>
        Save
      </Button>
    );
    return () => setNavContent(null);
  }, [setNavContent]);

  const fetchConfig = async () => {
    const response = await fetch(props.configLink + '/?rawdata=true', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + utilities.getAccessToken()
      }
    })

    if (response.ok) {
      const result = await response.json();
      setResultConfig(result.config);
    } else {
      utilities.addToast('Unable to load configuration', 5, 'danger');
    }

  };

  const saveConfig = async () => {
    const response = await fetch(props.configLink, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + utilities.getAccessToken()
      },
      body: (resultConfigRef.current)
    })
    if (response.ok) {
      utilities.addToast('Configuration saved', 5, 'success');
      setIsSaving(false);
    } else {
      utilities.addToast('Unable to save configuration', 5, 'danger');
      setIsSaving(false);
    }
  };

  useEffect(() => {
    fetchConfig();
  }, [])

  useEffect(() => {

    if (Object.keys(JSON.parse(resultConfig)).length === 0 || isLoaded) return;

    // Define your JSON schema
    const schema = {
      type: "object",
      title: " ",
      id: "settings",
      options: {
        "disable_properties": true,
        "disable_collapse": true
      },
      "properties": {
        "LaborNames": {
          "type": "array",
          "format": "table",
          "options": {
            "collapsed": true,
            "disable_array_delete_all_rows": true,
            "disable_array_delete_last_row": true
          },
          "title": "Employee Names",
          "uniqueItems": true,
          "items": {
            "id": "Name",
            "type": "object",
            "properties": {
              "FirstName": {
                "title": "First Name",
                "type": "string"
              },
              "LastName": {
                "title": "Last Name",
                "type": "string"
              },
              "FirstLast": {
                "type": "string",
                "template": "{{firstName}} {{lastName}}",
                "options": {
                  "hidden": true
                },
                "watch": {
                  "firstName": "Name.FirstName",
                  "lastName": "Name.LastName"
                }
              }
            }
          }
        },
        "LaborEquipmentOperated": {
          "type": "array",
          "format": "table",
          "options": {
            "collapsed": true,
            "disable_array_delete_all_rows": true,
            "disable_array_delete_last_row": true
          },
          "title": "Union Class",
          "uniqueItems": true,
          "items": {
            "title": "Equipment",
            "type": "string"
          }
        },
        "LaborEquipmentNumbers": {
          "type": "array",
          "format": "table",
          "options": {
            "collapsed": true,
            "disable_array_delete_all_rows": true,
            "disable_array_delete_last_row": true
          },
          "title": "Equipment Numbers",
          "uniqueItems": false,
          "items": {
            "type": "object",
            "id": "EquipmentNumber",
            "properties": {
              "EquipmentNumberPrefix1": {
                "title": "#",
                "type": "string",
                "maxLength": 4,
                "minLength": 2,
                "options": {
                  "input_width": "80px"
                }
              },
              "EquipmentNumberPrefix2": {
                "title": "##",
                "type": "string",
                "maxLength": 2,
                "minLength": 0,
                "options": {
                  "input_width": "50px"
                }
              },
              "EquipmentNumberName": {
                "title": "Equipment Number Name",
                "type": "string",
                "options": {
                  "input_width": "220px"
                }
              },
              "EquipmentNumberFull": {
                "type": "string",
                "title": "Full Equipment Number",
                "template": "#{{EquipmentNumberPrefix1}} {{EquipmentNumberPrefix2}} {{EquipmentNumberName}}",
                "options": {
                  "hidden": false,
                  "input_width": "220px"
                },
                "watch": {
                  "EquipmentNumberPrefix1": "EquipmentNumber.EquipmentNumberPrefix1",
                  "EquipmentNumberPrefix2": "EquipmentNumber.EquipmentNumberPrefix2",
                  "EquipmentNumberName": "EquipmentNumber.EquipmentNumberName"
                }
              }
            }
          }
        },
        "EquipmentRentalsCompany": {
          "type": "array",
          "format": "table",
          "options": {
            "collapsed": true,
            "disable_array_delete_all_rows": true,
            "disable_array_delete_last_row": true
          },
          "title": "Equipment Rentals Companies",
          "uniqueItems": true,
          "items": {
            "type": "string",
            "title": "Company",
            "options": {
              "input_width": "200px"
            }
          }
        },
        "HaulingCo": {
          "type": "array",
          "format": "table",
          "options": {
            "collapsed": true,
            "disable_array_delete_all_rows": true,
            "disable_array_delete_last_row": true
          },
          "title": "Hauling Co.",
          "uniqueItems": true,
          "items": {
            "type": "string",
            "title": "Co."
          }
        },
        "SuppliesAndMaterialsName": {
          "type": "array",
          "format": "table",
          "options": {
            "collapsed": true,
            "disable_array_delete_all_rows": true,
            "disable_array_delete_last_row": true
          },
          "title": "Supplies and Materials Name.",
          "uniqueItems": true,
          "items": {
            "type": "string",
            "title": "Name"
          }
        }
      }
    }

    // Create JSON Editor instance
    const editor = new JSONEditor(editorRef.current, {
      schema,
      disable_edit_json: true
      // theme: 'html',
      // iconlib: "fontawesome4"
    });

    editor.on('change', () => {
      setResultConfig(JSON.stringify(editor.getValue()))
      // setShowSaveButton(true);
    });

    setIsLoaded(true);

    editor.on('ready', () => {
      // Now you can interact with the editor
      editor.setValue(JSON.parse(resultConfig));
    });

    // return () => {
    //   // Clean up when the component is unmounted
    //   editor.destroy();
    // };
  }, [resultConfig]);

  return (
    <Container fluid className='tablet p-0 m-0'>
      <Row>
        <Col>
          <div id="config" ref={editorRef}></div>
        </Col>
      </Row>
    </Container>
  );
};

export default Config;
