import React, { useState, useEffect, useCallback } from 'react';
import ToastItem from './ToastItem';
import * as utilities from '../../utilities';
import 'bootstrap/dist/css/bootstrap.css';

const ToastPanel = (props) => {
    const [toastArray, setToastArray] = useState([]);

    const callback = useCallback((obj) => {
        utilities.log('ToastPanel.js/callback (obj)', obj);
        setToastArray((prevArray) => [
            ...prevArray,
            { str: obj.str, delay: obj.delay, color: obj.color, id: Math.random() },
        ]);
    }, []);

    const onDelete = useCallback((id) => {
        utilities.log('ToastPanel.js/onDelete (id)', id);
        setToastArray((prevArray) => {
            const index = prevArray.findIndex((item) => item.id === id);
            if (index > -1) {
                const newArray = [...prevArray];
                newArray.splice(index, 1);
                return newArray;
            }
            return prevArray;
        });
    }, []);

    useEffect(() => {
        utilities.log('ToastPanel.js/componentDidMount');
        utilities.addCallback(callback);

        return () => {
            // Clean up callback on unmount
            utilities.removeCallback(callback);
        };
    }, [callback]);

    utilities.info('ToastPanel.js/render');

    return (
        <>
            {toastArray.map((toast) => (
                <ToastItem
                    key={toast.id}
                    toast={toast}
                    onDelete={onDelete}
                    log={props.log}
                    info={props.info}
                />
            ))}
        </>
    );
};

export default ToastPanel;
