import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';

import "./DailyReportPreview.css"

const DailyReportPreview = (props) => {

    const [jobArray, setJobArray] = useState([]);

    useEffect(() => {
        let tmpArray = [];
        [1, 2, 3, 4].forEach(col => {
            Object.keys(props).forEach(key => {
                if (tmpArray.findIndex(item => item.col === col) === -1 && key.indexOf(`job${col}`) === 0 && props[key]) tmpArray.push({ col: col, rows: [] });
            })
        })
        tmpArray.forEach(job => {
            const rows = [...new Set(
                Object.keys(props)
                    // .filter(key => key.substring(0, 4) === `job${job.col}` && props[key])
                    .map(key => {
                        if (key.indexOf('job'+job.col) === -1) return 0;
                        const match = key.match(/(\d+)$/);  // Regex to find digits at the end of the string
                        // console.log('key',key,match,job)
                        return match ? parseInt(match[0], 10) : 0;  // Convert matched string to integer
                    })
                    .filter(char => !isNaN(parseInt(char)))
                    .map(char => parseInt(char))
            )];
            rows.shift();
            // console.log(rows,job);
            job.rows = rows; // [...Array(Math.max(...rows))].map((_, index) => index + 1);
        })
        setJobArray(tmpArray);
    }, [props])


    const showVar = (str) => {
        return !str ? <>&nbsp;</> : str;
    }

    return (
        <div id="">
            <div id="dailyReportPreviewContainer" className="page-container">
                <Container fluid>
                    <Row className='my-2 mx-0 text-end'>
                        <Col className="p-0">
                            <Button onClick={showVar(props.hidePreview)} color="secondary">Close</Button>
                        </Col>
                    </Row>
                </Container>
                {jobArray.length === 0 && <h1>No Jobs</h1>}
                {jobArray.map(item => item.col).map(col => (
                    <table>
                        <tbody>
                            <tr>
                                <td colSpan="2"><label>Date</label>
                                    <input disabled name="date" type="date" value={props.date || ''} />
                                </td>
                                <td><label>From</label>
                                    <input disabled name="shiftFrom" type="time" value={props.shiftFrom || ''} />
                                </td>
                                <td><label>To</label>
                                    <input disabled name="shiftTo" type="time" value={props.shiftTo || ''} />
                                </td>
                                <td colSpan="5"><label>Job</label>
                                    <input disabled name={`job${col}_job`} type="text" value={props[`job${col}_job`] || ''} />
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="4"><label>Foreman</label>
                                    <input disabled id="user" name="user" type="text" value={props.user} />
                                </td>
                                <td colSpan="5"><label>RFCO</label>
                                    <input disabled name={`job${col}_rfco`} type="text" value={props[`job${col}_rfco`] || ''} />
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="4"><label>Email</label>
                                    <input disabled id="email" name="email" type="text" value={props.email} />
                                </td>
                                <td colSpan="3"><label>Job No.</label>
                                    <input disabled name={`job${col}_jobNumber`} type="text" value={props[`job${col}_jobNumber`] || ''} />
                                </td>
                                <td colSpan="2"><label>Code No.</label>
                                    <input disabled name={`job${col}_codeNumber`} type="text" value={props[`job${col}_codeNumber`] || ''} />
                                </td>
                            </tr>
                            <tr>
                                <th width="20%" colSpan={2}>Employee Name</th>
                                {/* <th width="10%">Emp No.</th> */}
                                <th width="8%">Class</th>
                                <th width="8%">Rate</th>
                                <th width="8%">ST Hrs</th>
                                <th width="8%">OT Hrs</th>
                                <th width="8%">DBL Hrs</th>
                                <th width="20%">Equip No</th>
                                <th width="10%">Equip Hrs</th>
                            </tr>
                            {jobArray.find(item => item.col === col).rows.map(row => (<tr>
                                <td colSpan={2}>
                                    <input disabled name={`employeeName${row}`} type="text" value={props[`employeeName${row}`] || ''} />
                                    {/* <select disabled name={`employeeName${row}`}>
                                    <option>{props[`employeeName${row}`] || ''}</option>
                                </select> */}
                                </td>
                                {/* <td>
                                <input disabled name={`employeeNumber${row}`} type="text" value={props[`employeeNumber${row}`] || ''} />
                            </td> */}
                                <td className="lightBlueGrey">
                                    <input disabled name={`class${row}`} type="text" value={props[`class${row}`] || ''} />
                                </td>
                                <td className="lightBlueGrey">
                                    <input disabled name={`rate${row}`} min="0" step="0.5" type="number" value={props[`rate${row}`] || ''} />
                                </td>
                                <td>
                                    <input disabled name={`job${col}_standardHours${row}`} min="0" step="0.5" type="number" value={props[`job${col}_standardHours${row}`] || ''} />
                                </td>
                                <td>
                                    <input disabled name={`job${col}_overtimeHours${row}`} min="0" step="0.5" type="number" value={props[`job${col}_overtimeHours${row}`] || ''} />
                                </td>
                                <td>
                                    <input disabled name={`job${col}_doubleHours${row}`} min="0" step="0.5" type="number" value={props[`job${col}_doubleHours${row}`] || ''} />
                                </td>
                                <td className="lightBlueGrey">
                                    <input disabled name={`job${col}_equipmentNumber${row}`} type="text" value={props[`job${col}_equipmentNumber${row}`] || ''} />
                                    {/* <select disabled name={`job${col}_equipmentNumber${row}`}>
                                    <option>{props[`job${col}_equipmentNumber${row}`] || ''}</option>
                                </select> */}
                                </td>
                                <td className="lightBlueGrey">
                                    <input disabled name={`job${col}_equipmentHours${row}`} min="0" step="0.5" type="number" value={props[`job${col}_equipmentHours${row}`] || ''} />
                                </td>
                            </tr>))}
                            {props[`job${col}_equipmentRentals`] && <tr>
                                <td colSpan="4" className="stripedBG" >
                                    &nbsp;
                                </td>
                                <td colSpan="5"><label>Equipment Rentals</label>
                                    <textarea disabled name={`job${col}_equipmentRentals`} rows="2" value={props[`job${col}_equipmentRentals`]}></textarea>
                                </td>
                            </tr>}
                            {props[`job${col}_materialDeliveredToJobType`] && <tr>
                                <td colSpan="4" className="stripedBG" >
                                    &nbsp;
                                </td>
                                <td colSpan="5"><label>Material Delivered to Job - Type</label>
                                    <textarea disabled name={`job${col}_materialDeliveredToJobType`} rows="2" value={props[`job${col}_materialDeliveredToJobType`]}></textarea>
                                </td>
                            </tr>}
                            {props[`job${col}_materialDeliveredToJobQuantity`] && <tr>
                                <td colSpan="4" className="stripedBG" >
                                    &nbsp;
                                </td>
                                <td colSpan="5"><label>Material Delivered to Job - Quantity</label>
                                    <textarea disabled name={`job${col}_materialDeliveredToJobQuantity`} rows="2" value={props[`job${col}_materialDeliveredToJobQuantity`]}></textarea>
                                </td>
                            </tr>}
                            {props[`job${col}_materialDeliveredToJobFrom`] && <tr>
                                <td colSpan="4" className="stripedBG" >
                                    &nbsp;
                                </td>
                                <td colSpan="5"><label>Material Delivered to Job - From</label>
                                    <textarea disabled name={`job${col}_materialDeliveredToJobFrom`} rows="2" value={props[`job${col}_materialDeliveredToJobFrom`]}></textarea>
                                </td>
                            </tr>}
                            {props[`job${col}_materialDeliveredToJobHauledBy`] && <tr>
                                <td colSpan="4" className="stripedBG" >
                                    &nbsp;
                                </td>
                                <td colSpan="5"><label>Material Delivered to Job - Hauled By</label>
                                    <textarea disabled name={`job${col}_materialDeliveredToJobQHauledBy`} rows="2" value={props[`job${col}_materialDeliveredToJobHauledBy`]}></textarea>
                                </td>
                            </tr>}
                            <tr>
                                <td colSpan="4"><label>Foreman Signature</label>
                                    {props.foremanSignature && <img id="foremanSignature" src={props.foremanSignature} alt="Foreman Signature" />}
                                </td>
                                <td colSpan="5"><label>Comments</label>
                                    <textarea disabled name="comments" rows="3" value={props.comments || ''}></textarea>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                ))}
            </div>

        </div>

    )
}

export default DailyReportPreview;