import React, { Fragment, useState } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import ConfirmButton from '../ConfirmButton/ConfirmButton';

import "./SafetyAppPreview.css"

const SafetyAppPreviewEdit = (props) => {

    const [entry, setEntry] = useState(props.entry);

    const onChange = (e) => {
        let tempObj = { ...entry };
        tempObj[e.target.name] = e.target.value;
        setEntry(tempObj);
    }

    const checklistItems = [
        { title: 'Hand Injury', methods: `Wear correct gloves. Guards are in place. Use proper tools. Identify pinch points and sharp edges.`, id: 'handInjury' },
        { title: 'Fall Hazard', methods: `Inspect fall protection prior to use. Wear full body harness w/double locking shock absorbing lanyard. Ensure adequate anchor point. Ensure guard rail system is adequate. Proper ladder set up and use.`, id: 'fallHazard' },
        { title: 'Eye Injury', methods: `Safety Glasses, Update to Face Shield/Chemical Goggles or combination, when needed.`, id: 'eyeInjury' },
        { title: 'Foot Injury', methods: `Proper attire being worn? (Sturdy work shoes, steel toed shoes if needed, etc.). (Safety toed boots required on MILCON projects)`, id: 'footInjury' },
        { title: 'Sprains or Strains', methods: `Proper lifting techniques, Use lifting/carrying devices. Ensure rigging has been inspected. Use Buddy system.`, id: 'sprainsOrStrains' },
        { title: 'Struck By', methods: `Hard hats. Hi visibility vest. Back up alarms on equipment. Stay clear of overhead loads.`, id: 'struckBy' },
        { title: 'Head Injury', methods: `Identify low hanging objects with tape/tags. Wear proper head protection.`, id: 'headInjury' },
        { title: 'Excessive Noise', methods: `Wear appropriate hearing protection, (ear plugs, muffs or combination).`, id: 'excessiveNoise' },
        { title: 'Heat/Cold Stress', methods: `(Heat) Drink plenty of fluids. (Cold) Ensure adequate clothing. Limit exposure times.`, id: 'heatColdStress' },
        { title: 'Slippery Surfaces, Tripping Hazards', methods: `Identify all trip hazards and slip hazards. Good housekeeping, keep debris, cords, material clear of access and egress. Keep work area clean.`, id: 'slipperySurfacesTrippingHazards' },
        { title: 'Hot work - Any flame or spark producing work', methods: `Wear protective gloves, face shield and/or heat suits, as needed. Apply proper barriers for protection against heat sources.`, id: 'hotWork' },
        { title: 'Respiratory Hazard', methods: `If possible, ventilate area to eliminate hazards. Determine proper respirator required for hazard(s).`, id: 'resperatoryHazard' },
        { title: 'Electrical Hazard', methods: `Use GFCI, low voltage lighting. Ensure proper grounding of equipment. Inspect cords prior to use. ENSURE Lock out Tag out . Identify existing overhead or underground power lines.`, id: 'electricalHazard' },
        { title: 'Confined Space Entry', methods: `Identify ALL hazards of space. Determine if confined space permit is required. Ventilate as needed. Verify proper egress.`, id: 'confinedSpaceEntry' },
        { title: 'Skin Contact', methods: `Review product MSDS or SDS wear PPE accordingly.`, id: 'skinContact' },
        { title: 'Stored Energy LO/TO', methods: `Verify isolation of energy source with authorized personnel before beginning work. Ensure affected personal are notified.`, id: 'storedEnergyLOTO' },
        { title: 'Caught in Between Excavations, equipment, material', methods: `Bench Slope, Shore trench or excavation. Keep body parts clear of loads and material. Lock out and Tag out equipment during maintenance.`, id: 'caughtInBetween' },
    ]

    const hazardAssessmentItems = [
        { title: 'Have all personnel received all required site-specific training and on site orientation? (If no, stop and take action)', id: 'personnelTraining' },
        { title: 'Has equipment been inspected prior to use?', id: 'equipmentInspected' },
        { title: 'Is Crew familiar with evacuation routes?', id: 'crewEvacuation' },
        { title: 'Overhead or underground utilities present?', id: 'utilitiesPresent' },
        { title: 'If using devices such as ladders, forklifts, JLG or scissor lifts have the operators been properly trained? (If not, stop and take proper action.)', id: 'deviceTraining' },
        { title: 'Has the construction area been properly barricaded and signs posted where required? (If not stop and take action)', id: 'areaBarricaded' },
        { title: 'Have all necessary licenses, certificates and or proper training to complete the job been obtained? (If not stop and take action)', id: 'licensesAndCertificates' },
        { title: 'Work area - Could other trades present hazards?', id: 'workArea' },
    ]

    return (
        <div id="">
            <div id="safetyAppPreviewContainer">
                <Container fluid>
                    <Row className='my-2 mx-0 text-end'>
                        <Col className="p-0">
                            {JSON.stringify(props.entry) === JSON.stringify(entry) ?
                                <>
                                    <Button
                                        onClick={() => props.onUpdate(props.entryId, entry)}
                                        color="success"
                                        disabled={true}
                                        className='me-2'
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        onClick={props.hidePreview}
                                        color="secondary"
                                    >
                                        Close
                                    </Button>
                                </>
                                :
                                <>
                                    <Button
                                        onClick={() => props.onUpdate(props.entryId, entry)}
                                        color="success"
                                        className='me-2'
                                    >
                                        Save
                                    </Button>
                                    <ConfirmButton
                                        onClick={props.hidePreview}
                                        color="secondary"
                                        message="You have unsaved changes. Do you wish to continue?"
                                    >
                                        Close
                                    </ConfirmButton>
                                </>
                            }
                        </Col>
                    </Row>
                </Container>
                <table className='w-100'>
                    <tr>
                        <td width="50%" colSpan={2}><label>Job Site</label>
                            <input name="jobSite" value={entry.jobSite || ''} onChange={onChange} />
                        </td>
                        <td colSpan="2"><label>Date</label>
                            <input name="datePrepared" type="date" value={entry.datePrepared || ''} onChange={onChange} />
                        </td>
                    </tr>
                    <tr>
                        <td width="50%" colSpan={2}><label>Company Name</label>
                            <input name="companyName" value={entry.companyName || ''} onChange={onChange} />
                        </td>
                        <td colSpan="2"><label>Supervisor Name</label>
                            <input name="supervisorName" value={entry.supervisorName || ''} onChange={onChange} />
                        </td>
                    </tr>
                    <tr>
                        <td width="50%" colSpan={2}><label>Job Description</label>
                            <input name="jobDescription" value={entry.jobDescription || ''} onChange={onChange} />
                        </td>
                        <td colSpan="2"><label>Number of Revisions</label>
                            <input name="numberOfRevisions" value={entry.numberOfRevisions || ''} onChange={onChange} />
                        </td>
                    </tr>
                    <tr>
                        <td width="25%"><label>Submitted By</label>
                            <input name="user" value={props.user || ''} />
                        </td>
                        <td width="25%"><label>Email</label>
                            <input name="email" value={props.email || ''} />
                        </td>
                        <td colSpan="2"><label>Job #</label>
                            <input name="jobNumber" value={entry.jobNumber || ''} onChange={onChange} />
                        </td>
                    </tr>
                </table>

                <table className='w-100'>
                    <tr>
                        <th width="40%" colSpan={3}>
                            Potential Hazards
                        </th>
                        <th>
                            Methods to Control/Eliminate Hazard Identified
                        </th>
                    </tr>
                    {checklistItems.map((row, rowIndex) => (
                        <Fragment key={rowIndex}>
                            <tr>
                                <td rowSpan="2" width="20%" className='p-1'>{row.title}</td>
                                <td width="10%" rowSpan={2} className='p-0 text-center text-large'>
                                    <input
                                        className="checkedYes"
                                        type="radio"
                                        name={`checklist-radio_${row.id}`}
                                        id={`checklist-radio_${row.id}`}
                                        value={'Y'}
                                        checked={'Y' === entry[`checklist-radio_${row.id}`]}
                                        onChange={onChange} />
                                </td>
                                <td width="10%" rowSpan={2} className='p-0 text-center text-large'>
                                    <input
                                        className='checkedNo'
                                        type="radio"
                                        name={`checklist-radio_${row.id}`}
                                        id={`checklist-radio_${row.id}`}
                                        value={'N'}
                                        checked={'N' === entry[`checklist-radio_${row.id}`]}
                                        onChange={onChange} />
                                </td>
                                <td className='p-1'><em>{row.methods}</em></td>
                            </tr>
                            <tr>
                                <td className='p-0'>
                                    <input name={`checklist-task_${row.id}`} value={entry[`checklist-task_${row.id}`] || ''} onChange={onChange} />
                                </td>
                            </tr>
                        </Fragment>
                    ))}
                </table>

                <table className='w-100'>
                    <tr>
                        <th colSpan={3} className='text-danger'>Add any additional Tasks, Hazards, and Controls not previously listed (Cranes, Scaffolds, etc..)</th>
                    </tr>
                    <tr>
                        <th width="33%">
                            Describe Today's Tasks (Each Step)
                        </th>
                        <th width="33%">
                            Identify Potential Hazards
                        </th>
                        <th width="33%">
                            Ways to Eliminate/Control Hazards
                        </th>
                    </tr>
                    {new Array(7).fill(null)
                        .map((item, index) => {
                            if (!(entry[`task-description_${index + 1}`] || entry[`task-hazard_${index + 1}`] || entry[`task-solution_${index + 1}`])) return (<></>);
                            return (<tr key={index} className='m-0'>
                                <td className='p-0 border border-secondary '>
                                    <input name={`task-description_${index + 1}`} value={entry[`task-description_${index + 1}`] || ''} onChange={onChange} />
                                </td>
                                <td className='p-0 border border-secondary '>
                                    <input name={`task-hazard_${index + 1}`} value={entry[`task-hazard_${index + 1}`] || ''} onChange={onChange} />
                                </td>
                                <td className='p-0 border border-secondary '>
                                    <input name={`task-solution_${index + 1}`} value={entry[`task-solution_${index + 1}`] || ''} onChange={onChange} />
                                </td>
                            </tr>)
                        })}
                </table>

                <table className='w-100'>
                    <tr>
                        <th width="70%">
                            Additional Hazard Assessments
                        </th>
                        <th width="10%">
                            Yes
                        </th>
                        <th width="10%">
                            No
                        </th>
                        <th width="10%">
                            N/A
                        </th>
                    </tr>
                    {hazardAssessmentItems.map((row, rowIndex) => (
                        <Fragment key={rowIndex}>
                            <tr>
                                <td className='p-1'>{row.title}</td>
                                <td className='p-1 text-center text-large'>
                                    <input
                                        className="checkedYes"
                                        type="radio"
                                        name={`hazard-radio_${row.id}`}
                                        id={`hazard-radio_${row.id}`}
                                        value={'Y'}
                                        checked={'Y' === entry[`hazard-radio_${row.id}`]}
                                        onChange={onChange} />
                                </td>
                                <td className='p-1 text-center text-large'>
                                    <input
                                        className='checkedNo'
                                        type="radio"
                                        name={`hazard-radio_${row.id}`}
                                        id={`hazard-radio_${row.id}`}
                                        value={'N'}
                                        checked={'N' === entry[`hazard-radio_${row.id}`]}
                                        onChange={onChange} />
                                </td>
                                <td className='p-1 text-center text-large'>
                                    <input
                                        className='checkedNotApplicable'
                                        type="radio"
                                        name={`hazard-radio_${row.id}`}
                                        id={`hazard-radio_${row.id}`}
                                        value={'NA'}
                                        checked={'NA' === entry[`hazard-radio_${row.id}`]}
                                        onChange={onChange} />
                                </td>
                            </tr>
                        </Fragment>
                    ))}
                </table>

                <table className='w-100'>
                    <tr>
                        <th width="50%">
                            Name
                        </th>
                        <th width="50%">
                            Name
                        </th>
                    </tr>
                    {new Array(14).fill(null).map((item, index) => {
                        if (!entry[`name_${(index * 2) + 1}`] && !entry[`name_${(index * 2) + 2}`]) return (<></>);
                        return (<tr key={index} className='p-0 border border-secondary '>
                            <td>
                                {/* <input name={`name_${(index * 2) + 1}`} value={entry[`name_${(index * 2) + 1}`] || ''} onChange={onChange} /> */}
                                {entry[`name_${(index*2)+1}`] &&
                                            <img className="signatureNamesPreview" src={entry[`name_${(index * 2)+1}`]} alt="Signature" />}
                            </td>
                            <td>
                                {/* <input name={`name_${(index * 2) + 2}`} value={entry[`name_${(index * 2) + 2}`] || ''} onChange={onChange} /> */}
                                {entry[`name_${(index*2)+2}`] &&
                                            <img className="signatureNamesPreview" src={entry[`name_${(index * 2)+2}`]} alt="Signature" />}
                            </td>
                        </tr>)
                    })}
                </table>
            </div>

        </div>

    )
}

export default SafetyAppPreviewEdit;