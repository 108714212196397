import React, { useState, useContext } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row, Col, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import NavContext from './NavContext';
import * as utilities from '../../utilities';

const Nav = (props) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleMenu = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const { navContent } = useContext(NavContext);

    return (
        <>
            <Row className='m-0 p-0 align-items-end bg-black' id="appHeader">
                <Col xs={4} className='m-0 p-0 pe-3'>
                    <Row>
                        <Col className='m-0'>
                            <img id="logo" src="/logo-asta-construction.png" alt="ASTA Construction" onClick={props.handleHome} />
                        </Col>
                    </Row>
                </Col>
                <Col className='m-0 px-0 py-2 text-end'>
                    {navContent}
                </Col>
                <Col sm={1} xs={2} className='m-0 px-2 py-2 text-end'>
                    <Dropdown isOpen={dropdownOpen} toggle={toggleMenu} direction='down'>
                        <DropdownToggle nav tag="span" data-toggle="dropdown" aria-expanded={dropdownOpen}>
                            <Button
                                className="text-light mb-1"
                                outline
                                title="Menu"
                            >
                                <FontAwesomeIcon icon={faBars} />
                            </Button>
                        </DropdownToggle>
                        <DropdownMenu dark className='mt-2 py-2' end>
                            {props.links.map((link, linkIndex) => {
                                if (utilities.checkPermission(link.permissionGUID)) {
                                    return (
                                        <DropdownItem
                                            key={link.url}
                                            // activeClassName="navbar__link_xs--active"
                                            className={"navbar__link_xs m-0" + (link.small || linkIndex === 0 ? "" : ' border-top border-secondary')}
                                        >
                                            <NavLink
                                                className={"py-1 px-1 text-white" + (link.small ? " navSmall ms-2 fw-normal" : '')}
                                                to={link.url}
                                                onClick={() => { if (link.cb != null) link.cb(); }}
                                            >{link.link}</NavLink>
                                        </DropdownItem>
                                    );
                                } else {
                                    return null;
                                }
                            })}
                            <DropdownItem
                                className="navbar__link_xs my-0 border-top border-secondary"
                            >
                                <NavLink
                                    className="py-1 px-1 text-info "
                                    to="/"
                                    onClick={() => { props.logout(); }}
                                >Logout</NavLink>
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown >

                </Col>
            </Row>
        </>
    );
};

export default Nav;
