import React, { useState, useEffect, useCallback } from 'react';
import { Toast, ToastHeader, Fade } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.css';
import * as utilities from '../../utilities';

const ToastItem = (props) => {
    const [show, setShow] = useState(true);
    const timeoutRef = React.useRef(null);

    useEffect(() => {
        utilities.log('ToastItem.js/componentDidMount');

        if (parseInt(props.toast.delay) > 0) {
            timeoutRef.current = setTimeout(() => {
                setShow(false);
            }, parseInt(props.toast.delay * 1000));
        }

        return () => {
            utilities.log('ToastItem.js/componentWillUnmount');
            clearTimeout(timeoutRef.current);
        };
    }, [props.toast.delay]);

    const onDismiss = useCallback(() => {
        utilities.log('ToastItem.js/onDismiss');
        setShow(false);
    }, []);

    const onClose = useCallback(() => {
        utilities.log('ToastItem.js/onClose');
        clearTimeout(timeoutRef.current);
        props.onDelete(props.toast.id);
    }, [props.onDelete, props.toast.id]);

    utilities.info('ToastItem.js/render');

    return (
        <Fade in={show} onExited={onClose}>
            <Toast className={`m-1 text-left bg-${props.toast.color}`} animation="false">
                <ToastHeader toggle={onDismiss}>{props.toast.str}</ToastHeader>
            </Toast>
        </Fade>
    );
};

export default ToastItem;
