import React, { useState, useEffect, useContext } from 'react';
import { Row, Col, Label, Input, Nav, NavItem, NavLink, TabPane, TabContent, Button } from 'reactstrap';
import classnames from 'classnames';
import SafetyAppPreview from '../components/Preview/SafetyAppPreview';
import ConfirmButton from '../components/ConfirmButton/ConfirmButton';
import CanvasModal from '../components/Canvas/CanvasModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import NavContext from '../components/Nav/NavContext';
import * as utilities from '../utilities';


const SafetyApp = (props) => {
    const [activeTab, setActiveTab] = useState(0);
    const [showPreview, setShowPreview] = useState(false);
    const [user, setUser] = useState(localStorage.getItem('user') != null ? localStorage.getItem('user') : '');
    const [email, setEmail] = useState(localStorage.getItem('email') != null ? localStorage.getItem('email') : '');

    const [stateVariables, setStateVariables] = useState(localStorage.getItem('stateVariables') != null ? JSON.parse(localStorage.getItem('stateVariables')) : {});

    const [config] = useState(localStorage.getItem('config') != null ? JSON.parse(localStorage.getItem('config')) : []);
    const [entries, setEntries] = useState(localStorage.getItem('entries') != null ? JSON.parse(localStorage.getItem('entries')) : []);

    const { setNavContent } = useContext(NavContext);

    useEffect(() => {
        setNavContent(
            <>
                <ConfirmButton
                    color="danger"
                    className='px-4 me-2 mb-1'
                    onClick={() => initializeStateVariables(true)}
                >
                    Reset
                </ConfirmButton>
                <Button
                    color="primary"
                    className='px-4 me-2 mb-1'
                    onClick={() => setShowPreview(true)}
                >
                    Preview
                </Button>
                <Button
                    color="success"
                    className='px-4 me-2 mb-1'
                    onClick={saveEntry}
                    disabled={(stateVariables[props.config]?.jobNumber === '' || !stateVariables[props.config]?.jobNumber)}
                >
                    Submit
                </Button>
            </>

        );
        return () => setNavContent(null);
    }, [setNavContent, stateVariables]);

    const generateRandomHex = () => {
        const randomHex = Math.floor(Math.random() * 0xFFFFFFFF).toString(16).toUpperCase();
        return randomHex.padStart(8, '0');
    }

    const checklistItems = [
        { title: 'Hand Injury', methods: `Wear correct gloves. Guards are in place. Use proper tools. Identify pinch points and sharp edges.`, id: 'handInjury' },
        { title: 'Fall Hazard', methods: `Inspect fall protection prior to use. Wear full body harness w/double locking shock absorbing lanyard. Ensure adequate anchor point. Ensure guard rail system is adequate. Proper ladder set up and use.`, id: 'fallHazard' },
        { title: 'Eye Injury', methods: `Safety Glasses, Update to Face Shield/Chemical Goggles or combination, when needed.`, id: 'eyeInjury' },
        { title: 'Foot Injury', methods: `Proper attire being worn? (Sturdy work shoes, steel toed shoes if needed, etc.). (Safety toed boots required on MILCON projects)`, id: 'footInjury' },
        { title: 'Sprains or Strains', methods: `Proper lifting techniques, Use lifting/carrying devices. Ensure rigging has been inspected. Use Buddy system.`, id: 'sprainsOrStrains' },
        { title: 'Struck By', methods: `Hard hats. Hi visibility vest. Back up alarms on equipment. Stay clear of overhead loads.`, id: 'struckBy' },
        { title: 'Head Injury', methods: `Identify low hanging objects with tape/tags. Wear proper head protection.`, id: 'headInjury' },
        { title: 'Excessive Noise', methods: `Wear appropriate hearing protection, (ear plugs, muffs or combination).`, id: 'excessiveNoise' },
        { title: 'Heat/Cold Stress', methods: `(Heat) Drink plenty of fluids. (Cold) Ensure adequate clothing. Limit exposure times.`, id: 'heatColdStress' },
        { title: 'Slippery Surfaces, Tripping Hazards', methods: `Identify all trip hazards and slip hazards. Good housekeeping, keep debris, cords, material clear of access and egress. Keep work area clean.`, id: 'slipperySurfacesTrippingHazards' },
        { title: 'Hot work - Any flame or spark producing work', methods: `Wear protective gloves, face shield and/or heat suits, as needed. Apply proper barriers for protection against heat sources.`, id: 'hotWork' },
        { title: 'Respiratory Hazard', methods: `If possible, ventilate area to eliminate hazards. Determine proper respirator required for hazard(s).`, id: 'resperatoryHazard' },
        { title: 'Electrical Hazard', methods: `Use GFCI, low voltage lighting. Ensure proper grounding of equipment. Inspect cords prior to use. ENSURE Lock out Tag out . Identify existing overhead or underground power lines.`, id: 'electricalHazard' },
        { title: 'Confined Space Entry', methods: `Identify ALL hazards of space. Determine if confined space permit is required. Ventilate as needed. Verify proper egress.`, id: 'confinedSpaceEntry' },
        { title: 'Skin Contact', methods: `Review product MSDS or SDS wear PPE accordingly.`, id: 'skinContact' },
        { title: 'Stored Energy LO/TO', methods: `Verify isolation of energy source with authorized personnel before beginning work. Ensure affected personal are notified.`, id: 'storedEnergyLOTO' },
        { title: 'Caught in Between Excavations, equipment, material', methods: `Bench Slope, Shore trench or excavation. Keep body parts clear of loads and material. Lock out and Tag out equipment during maintenance.`, id: 'caughtInBetween' },
    ]

    const hazardAssessmentItems = [
        { title: 'Have all personnel received all required site-specific training and on site orientation? (If no, stop and take action)', id: 'personnelTraining' },
        { title: 'Has equipment been inspected prior to use?', id: 'equipmentInspected' },
        { title: 'Is Crew familiar with evacuation routes?', id: 'crewEvacuation' },
        { title: 'Overhead or underground utilities present?', id: 'utilitiesPresent' },
        { title: 'If using devices such as ladders, forklifts, JLG or scissor lifts have the operators been properly trained? (If not, stop and take proper action.)', id: 'deviceTraining' },
        { title: 'Has the construction area been properly barricaded and signs posted where required? (If not stop and take action)', id: 'areaBarricaded' },
        { title: 'Have all necessary licenses, certificates and or proper training to complete the job been obtained? (If not stop and take action)', id: 'licensesAndCertificates' },
        { title: 'Work area - Could other trades present hazards?', id: 'workArea' },
    ]

    const initializeStateVariables = (force) => {
        if (!stateVariables[props.config] || force) {
            const initialState = {
                date: new Date().toISOString().substring(0, 10),
                _TransactionCode: generateRandomHex(),
                _Timestamp: null,
                _Submitted: false
            };
            setStateVariables({ [props.config]: initialState });
            let obj = {};
            if (localStorage.getItem('stateVariables')) {
                obj = JSON.parse(localStorage.getItem('stateVariables'));
            };
            obj[props.config] = initialState;
            localStorage.setItem('stateVariables', JSON.stringify(obj))
        }
    };

    const handleInputChange = (key, e) => {
        setStateVariables((prev) => {
            let obj = { ...prev };
            switch (e.target.type) {
                case 'radio':
                    obj[props.config][key] = e.target.value;
                    break;
                default:
                    obj[props.config][key] = String(e.target.value) === '0' ? '' : e.target.value;
            }
            return {
                ...obj
            }
        });
    };

    const displayInputValue = (val) => {
        return (val != null) ? val : '';
    }

    const displayInput = (field) => {
        switch (field.type) {
            case "number":
                return (<Input
                    className={'m-0 p-1 rounded-0 ' + (field.className || '')}
                    style={{ textAlign: field.alignment }}
                    type={field.type}
                    name={field.name}
                    min={field.min || 0}
                    max={field.max}
                    step={field.step || .5}
                    value={displayInputValue(stateVariables?.[props.config]?.[field.name])}
                    onChange={(e) => handleInputChange(field.name, e)}
                />);
            case "radio":
                // {displayInput({ type: 'radio', name: `checklist-radio_${item.id}`, value: 'Y', icon: faCheck, state: 'yes', className: 'checkedYes' })}
                return (<div className="radio-container" >
                    <Input
                        className={'m-0 p-4 border-0 rounded-0 ' + (field.className || '')}
                        type={field.type}
                        name={field.name}
                        id={field.name}
                        value={field.value}
                        checked={field.value === stateVariables?.[props.config]?.[field.name]}
                        onChange={(e) => handleInputChange(field.name, e)}
                    />
                    <Label id={field.name} className={"custom-radio " + field.state}><FontAwesomeIcon icon={field.icon} /></Label>
                </div>
                );
            case "select":
                return (<Input
                    className={'m-0 p-1 rounded-0 ' + (field.className || '')}
                    style={{ textAlign: field.alignment }}
                    type={field.type}
                    name={field.name}
                    value={displayInputValue(stateVariables?.[props.config]?.[field.name])}
                    onChange={(e) => handleInputChange(field.name, e)}
                >
                    <option value=""></option>
                    {field.type === 'select' && config[props.config]?.[field.optionValues].map(option => (
                        <option key={option}>{option}</option>
                    ))}
                </Input>);
            case "textarea":
                return (<Input
                    className={'m-0 p-1 rounded-0 ' + (field.className || '')}
                    style={{ textAlign: field.alignment || 'left' }}
                    type={field.type}
                    name={field.name}
                    value={displayInputValue(stateVariables?.[props.config]?.[field.name])}
                    onChange={(e) => handleInputChange(field.name, e)}
                    placeholder={field.placeholder || ''}
                    rows={field.rows || 2}
                />)
            default:
                return (<Input
                    className={'m-0 p-1 rounded-0 ' + (field.className || '')}
                    style={{ textAlign: field.alignment || 'left' }}
                    type={field.type || 'text'}
                    name={field.name}
                    value={displayInputValue(stateVariables?.[props.config]?.[field.name])}
                    onChange={(e) => handleInputChange(field.name, e)}
                    placeholder={field.placeholder || ''}
                />)
        }
    }

    const saveEntry = () => {
        let obj = {};
        if (localStorage.getItem('entries') != null) {
            obj = JSON.parse(localStorage.getItem('entries'));
        };
        if (obj[props.config] == null) obj[props.config] = [];
        stateVariables[props.config]['_Timestamp'] = (new Date().getTime()) / 1000;
        stateVariables[props.config]['_User'] = user;
        stateVariables[props.config]['_Email'] = email;
        obj[props.config].push(Object.entries(stateVariables[props.config]).reduce((acc, [key, val]) => {
            if ((val != null && val !== '') || String(key).substring(0, 1) === '_') acc[key] = val;
            return acc;
        }, {}));
        localStorage.setItem('entries', JSON.stringify(obj));
        setEntries(obj);
        initializeStateVariables(true);
    }

    const submitEntries = async () => {
        entries[props.config] && entries[props.config].filter(entry => !entry['_Submitted']).forEach(async entry => {
            const response = await fetch(props.submitLink, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + utilities.getAccessToken()
                },
                body: JSON.stringify(entry)
            })
            if (response.ok) {
                utilities.addToast('Submission successful', 5, 'success');
                const result = await response.json();
                let obj = { ...entries };
                const entryIndex = obj[props.config].findIndex(entry => entry['_TransactionCode'] === result.transactionCode && !entry['_Submitted']);
                if (entryIndex > -1) {
                    obj[props.config][entryIndex]['_Submitted'] = true;
                    setEntries(obj);
                    localStorage.setItem('entries', JSON.stringify(obj));
                }
            } else {
                utilities.addToast('Submission unsuccessful, however the data is being stored on your device and will be uploaded once connected.', 5, 'danger')
            }
        })
    }

    useEffect((e) => {
        initializeStateVariables();
    }, [props.config]);

    useEffect((e) => {
        localStorage.setItem('user', user)
    }, [user]);

    useEffect((e) => {
        submitEntries();
    }, [entries]);

    useEffect((e) => {
        localStorage.setItem('email', email)
    }, [email]);

    useEffect((e) => {
        let obj = {};
        if (localStorage.getItem('stateVariables') != null) {
            obj = JSON.parse(localStorage.getItem('stateVariables'));
        };
        obj = { ...obj, ...stateVariables };
        localStorage.setItem('stateVariables', JSON.stringify(obj))
    }, [stateVariables]);

    return (
        <div className='tablet p-0 m-0' id="safetyAppContainer">
            {showPreview && <SafetyAppPreview
                hidePreview={() => setShowPreview(false)}
                {...stateVariables[props.config]}
                user={user}
                email={email}
            />}

            <Row className='m-0' id="safetyAppContent">
                <Col xs={4} className='p-0 bg-secondary'>
                    <Row className='m-0 bg-secondary'>
                        <Col className='p-0 border border-righst border-secondary'>
                            <Row className='m-0'>
                                <Col className='p-0'>
                                    {displayInput({ name: `jobNumber`, placeholder: 'Job #' })}
                                </Col>
                            </Row>
                            <Row className='m-0'>
                                <Col className='p-0'>
                                    <Input
                                        className='rounded-0 p-1 m-0'
                                        id="user"
                                        name="user"
                                        placeholder="User"
                                        value={user}
                                        onChange={(e) => setUser(e.target.value)}
                                    />
                                </Col>
                            </Row>
                            <Row className='m-0'>
                                <Col className='p-0'>
                                    <Input
                                        className='rounded-0 p-1 m-0'
                                        id="email"
                                        name="email"
                                        placeholder='Email'
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col xs={4} className='p-0 bg-secondary'>
                    <Row className='m-0 bg-secondary'>
                        <Col className='p-0 border border-rigxht border-secondary'>
                            <Row className='m-0'>
                                <Col className='p-0'>
                                    {displayInput({ name: `jobSite`, placeholder: 'Job Site' })}
                                </Col>
                            </Row>
                            <Row className='m-0'>
                                <Col className='p-0'>
                                    {displayInput({ name: `companyName`, placeholder: 'Company Name' })}
                                </Col>
                            </Row>
                            <Row className='m-0'>
                                <Col className='p-0'>
                                    {displayInput({ name: `jobDescription`, placeholder: 'Job Description' })}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col xs={4} className='p-0 bg-secondary'>
                    <Row className='m-0 bg-secondary'>
                        <Col className='p-0 border border-rigxht border-secondary'>
                            <Row className='m-0'>
                                <Col className='p-0'>
                                    {displayInput({ type: 'date', name: `datePrepared` })}
                                </Col>
                            </Row>
                            <Row className='m-0'>
                                <Col className='p-0'>
                                    {displayInput({ name: `supervisorName`, placeholder: 'Supervisor Name' })}
                                </Col>
                            </Row>
                            <Row className='m-0'>
                                <Col className='p-0'>
                                    {displayInput({ name: `numberOfRevisions`, placeholder: 'Number of Revisions' })}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row className='row-cols-lg-auto g-3 align-items-center mt-0 mb-0' id="tabs">
                <Col>
                    <Nav tabs className="pb-0 px-4">
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === 0 })}
                                onClick={() => { setActiveTab(0); }}>
                                Checklist
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === 1 })}
                                onClick={() => { setActiveTab(1); }}>
                                Tasks
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === 2 })}
                                onClick={() => { setActiveTab(2); }}>
                                Additional Hazard Assessments
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === 3 })}
                                onClick={() => { setActiveTab(3); }}>
                                Names
                            </NavLink>
                        </NavItem>
                    </Nav>
                </Col>
            </Row>

            <Row className='g-3 align-items-start pt-2 mb-0' id="tabContent">
                <Col className='p-0 m-0'>

                    <TabContent activeTab={activeTab} className="pt-2 px-0 mx-2">

                        <TabPane tabId={0}>
                            <Row className='m-0 text-uppercase text-secondary font-weight-bold lightBlueGrey'>
                                <Col xs={4} className='p-2'>
                                    POTENTIAL HAZARDS
                                </Col>
                                <Col xs={8} className='p-2'>
                                    METHODS TO CONTROL/ELIMINATE HAZARD IDENTIFIED
                                </Col>
                            </Row>
                            {checklistItems.map(item => (
                                <Row className='m-0 p-0' key={item.id}>
                                    <Col xs={2} className='p-2 border border-secondary font-weight-bold'>
                                        {item.title}
                                    </Col>
                                    <Col xs={1} className='p-0 border border-secondary justify-content-center d-flex align-items-center radio-yes'>
                                        {displayInput({ type: 'radio', name: `checklist-radio_${item.id}`, value: 'Y', icon: faCheck, state: 'yes', className: 'checkedYes' })}
                                    </Col>
                                    <Col xs={1} className='p-0 border border-secondary justify-content-center d-flex align-items-center radio-no'>
                                        {displayInput({ type: 'radio', name: `checklist-radio_${item.id}`, value: 'N', icon: faTimes, state: 'no', className: 'checkedNo' })}
                                    </Col>
                                    <Col xs={8} className='p-0 m-0'>
                                        <Row className='p-0 m-0'>
                                            <Col className='p-1 border border-secondary'>
                                                {item.methods}
                                            </Col>
                                        </Row>
                                        <Row className='p-0 m-0'>
                                            <Col className='p-0 border border-secondary'>
                                                {displayInput({ type: 'text', name: `checklist-task_${item.id}`, placeholder: 'Exposing Task:' })}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            ))}
                        </TabPane>

                        <TabPane tabId={1}>
                            <Row className='m-0 text-uppercase text-secondary font-weight-bold lightBlueGrey'>
                                <Col xs={4} className='p-2'>
                                    DESCRIBE TODAY'S TASKS (EACH STEP)
                                </Col>
                                <Col xs={4} className='p-2'>
                                    IDENTIFY POTENTIAL HAZARDS
                                </Col>
                                <Col xs={4} className='p-2'>
                                    WAYS TO ELIMINATE/CONTROL HAZARDS
                                </Col>
                            </Row>
                            {new Array(7).fill(null).map((item, index) => (
                                <Row key={index} className='m-0'>
                                    <Col xs={4} className='p-0 border border-secondary '>
                                        {displayInput({ type: 'textarea', name: `task-description_${index + 1}`, placeholder: `Task  ${index + 1}` })}
                                    </Col>
                                    <Col xs={4} className='p-0 border border-secondary '>
                                        {displayInput({ type: 'textarea', name: `task-hazard_${index + 1}`, placeholder: `Hazard  ${index + 1}` })}
                                    </Col>
                                    <Col xs={4} className='p-0 border border-secondary '>
                                        {displayInput({ type: 'textarea', name: `task-solution_${index + 1}`, placeholder: `Solution ${index + 1}` })}
                                    </Col>
                                </Row>
                            ))}
                        </TabPane>

                        <TabPane tabId={2} >
                            <Row className='m-0 text-uppercase text-secondary font-weight-bold lightBlueGrey'>
                                <Col xs={9} className='p-2'>
                                    ADDITIONAL HAZARD ASSESSMENT
                                </Col>
                                <Col xs={1} className='p-2 text-center'>
                                    YES
                                </Col>
                                <Col xs={1} className='p-2 text-center'>
                                    NO
                                </Col>
                                <Col xs={1} className='p-2 text-center'>
                                    N/A
                                </Col>
                            </Row>
                            {hazardAssessmentItems.map(item => (
                                <Row className='m-0 p-0' key={item.id}>
                                    <Col xs={9} className='p-2 border border-secondary font-weight-bold'>
                                        {item.title}
                                    </Col>
                                    <Col xs={1} className='p-2 border border-secondary justify-content-center d-flex align-items-center radio-yes'>
                                        {displayInput({ type: 'radio', name: `hazard-radio_${item.id}`, value: 'Y', icon: faCheck, state: 'yes', className: 'checkedYes' })}
                                    </Col>
                                    <Col xs={1} className='p-2 border border-secondary justify-content-center d-flex align-items-center radio-no'>
                                        {displayInput({ type: 'radio', name: `hazard-radio_${item.id}`, value: 'N', icon: faBan, state: 'no', className: 'checkedNo' })}
                                    </Col>
                                    <Col xs={1} className='p-2 border border-secondary justify-content-center d-flex align-items-center radio-no'>
                                        {displayInput({ type: 'radio', name: `hazard-radio_${item.id}`, value: 'NA', icon: faTimes, state: 'notApplicable', className: 'checkedNotApplicable' })}
                                    </Col>
                                </Row>
                            ))}
                        </TabPane>

                        <TabPane tabId={3}>
                            <Row className='m-0 text-uppercase text-secondary font-weight-bold lightBlueGrey'>
                                <Col xs={6} className='p-2'>
                                    NAME
                                </Col>
                                <Col xs={6} className='p-2'>
                                    NAME
                                </Col>
                            </Row>
                            <Row className='m-0 p-0' >
                                {new Array(28).fill(null).map((item, index) => (
                                    <Col key={index} xs={6} className='p-0 border border-secondary'>
                                        {/* {displayInput({ type: 'text', name: `name_${index + 1}` })} */}
                                        
                                        <CanvasModal
                                            arrkey={`name_${index + 1}`}
                                            saveimage={setStateVariables}
                                            config={props.config}
                                        >
                                            <Input
                                                className='px-1 m-0 border-0 rounded-0 w-100 bg-transparent'
                                                id={`name_${index + 1}`}
                                                name={`name_${index + 1}`}
                                                readOnly={true}
                                                style={{ width: "100%", height: '100px' }}
                                                placeholder={`Name ${index + 1}`}
                                            />
                                        </CanvasModal>
                                        {stateVariables[props.config]?.[`name_${index+1}`] &&
                                            <img className="signatureNames" src={stateVariables[props.config][`name_${index+1}`]} alt="Signature" />}
                                    </Col>
                                ))}
                            </Row>
                        </TabPane>


                    </TabContent>
                </Col>
            </Row>

        </div >
    );
};

export default SafetyApp;