import React, { useState, useEffect, useContext } from 'react';
import { Row, Col, Input, Button } from 'reactstrap';
import CreatableSelect from 'react-select/creatable'
import CanvasModal from '../components/Canvas/CanvasModal';
import DailyReportPreview from '../components/Preview/DailyReportPreview';
import NavContext from '../components/Nav/NavContext';
import ConfirmButton from '../components/ConfirmButton/ConfirmButton';
import * as utilities from '../utilities';


const DailyReport = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [user, setUser] = useState(localStorage.getItem('user') != null ? localStorage.getItem('user') : '');
    const [email, setEmail] = useState(localStorage.getItem('email') != null ? localStorage.getItem('email') : '');

    const [stateVariables, setStateVariables] = useState(localStorage.getItem('stateVariables') != null ? JSON.parse(localStorage.getItem('stateVariables')) : {});

    const [config, setConfig] = useState(localStorage.getItem('config') != null ? JSON.parse(localStorage.getItem('config')) : []);
    const [entries, setEntries] = useState(localStorage.getItem('entries') != null ? JSON.parse(localStorage.getItem('entries')) : []);

    const { setNavContent } = useContext(NavContext);

    useEffect(() => {
        setNavContent(
            <>
                <ConfirmButton
                    color="danger"
                    className='px-4 me-2 mb-1'
                    onClick={() => initializeStateVariables(true)}
                >
                    Reset
                </ConfirmButton>
                <Button
                    color="primary"
                    className='px-4 me-2 mb-1'
                    onClick={() => setShowPreview(true)}
                >
                    Preview
                </Button>
                <Button
                    color="success"
                    className='px-4 me-2 mb-1'
                    onClick={saveEntry}
                    disabled={(stateVariables[props.config]?.foremanSignature === '' || !stateVariables[props.config]?.foremanSignature)}
                >
                    Submit
                </Button>
            </>

        );
        return () => setNavContent(null);
    }, [setNavContent, stateVariables]);

    const generateRandomHex = () => {
        const randomHex = Math.floor(Math.random() * 0xFFFFFFFF).toString(16).toUpperCase();
        return randomHex.padStart(8, '0');
    }

    const initializeStateVariables = (force) => {
        if (!stateVariables[props.config] || force) {
            const initialState = {
                date: new Date().toISOString().substring(0, 10),
                _TransactionCode: generateRandomHex(),
                _Timestamp: null,
                _Submitted: false
            };
            setStateVariables({ [props.config]: initialState });
            let obj = {};
            if (localStorage.getItem('stateVariables')) {
                obj = JSON.parse(localStorage.getItem('stateVariables'));
            };
            obj[props.config] = initialState;
            localStorage.setItem('stateVariables', JSON.stringify(obj))
        }
    };

    const handleInputChange = (key, e) => {
        setStateVariables((prev) => {
            let obj = { ...prev };
            obj[props.config][key] = String(e.target.value) === '0' ? '' : e.target.value;
            if (e.target.value === '' || e.target.value === '0' || e.target.value === 0) delete obj[props.config][key];
            obj[props.config] = { ...obj[props.config]/*, ...totalArr*/ }
            return {
                ...obj
            }
        });
    };

    const fetchConfig = async () => {
        const response = await fetch(props.configLink, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + utilities.getAccessToken()
            }
        })
        setIsLoading(false);
        if (response.ok) {
            const result = await response.json();
            const resultConfig = (result.config);
            let configObj = {};
            if (localStorage.getItem('config')) {
                configObj = JSON.parse(localStorage.getItem('config'));
            };
            configObj = { ...configObj, [props.config]: resultConfig };
            setConfig(configObj);
            localStorage.setItem('config', JSON.stringify(configObj));

            if (!localStorage.getItem('stateVariables')) initializeStateVariables();
        } else {
            utilities.addToast('Unable to load latest configuration', 5, 'warning');
        }

    };

    const displayInputValue = (val) => {
        return (val != null) ? val : '';
    }

    const customSelectStyles = {
        control: (provided) => ({
            ...provided,
            minHeight: '34px',
            height: '34px',
            borderRadius: 0,
            border: '1px solid #dee2e6'
        }),
        valueContainer: (provided) => ({
            ...provided,
            height: '34px', // Adjust height
            padding: '0 6px' // Adjust padding to align text if necessary
        }),
        input: (provided) => ({
            ...provided,
            padding: '0',
            margin: '0px', // Remove default margins
        }),
        indicatorsContainer: (provided) => ({
            ...provided,
            height: '34px', // Adjust the height of indicators (like dropdown arrow)
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            padding: '5px', // Adjust padding of the dropdown indicator icon
        }),
        clearIndicator: (provided) => ({
            ...provided,
            padding: '5px', // Adjust padding of the clear indicator icon
        }),
        indicatorSeparator: () => ({
            display: 'none', // This hides the indicator separator
        }),
    };

    const displayInput = (field) => {
        switch (field.type) {
            case "number":
                return (<Input
                    className={'m-0 p-1 rounded-0 ' + (field.className || '')}
                    style={{ textAlign: field.alignment }}
                    type={field.type}
                    name={field.name}
                    min={field.min || 0}
                    max={field.max}
                    step={field.step || .5}
                    value={displayInputValue(stateVariables?.[props.config]?.[field.name])}
                    onChange={(e) => handleInputChange(field.name, e)}
                />);
            case "select":
                const options = (config[props.config] && config[props.config][field.optionValues]) ?
                    config[props.config][field.optionValues].map(option => ({
                        value: option,
                        label: option
                    })) : [];
                const displayValue = displayInputValue(stateVariables?.[props.config]?.[field.name]);
                return (
                    <CreatableSelect
                        className={'m-0 p-0 rounded-0 ' + (field.className || '')}
                        styles={customSelectStyles}
                        name={field.name}
                        onChange={(e) => handleInputChange(field.name, { target: e })}
                        value={displayValue ? { value: displayValue, label: displayValue } : { value: '', label: 'SELECT' }}
                        options={[
                            { value: 0, label: 'SELECT' },
                            ...options
                        ]}
                    />
                )
            case "textarea":
                return (<Input
                    className={'m-0 p-1 rounded-0 ' + (field.className || '')}
                    style={{ textAlign: field.alignment || 'left' }}
                    type={field.type}
                    name={field.name}
                    value={displayInputValue(stateVariables?.[props.config]?.[field.name])}
                    onChange={(e) => handleInputChange(field.name, e)}
                    placeholder={field.placeholder || ''}
                    rows={field.rows || 2}
                />)
            default:
                return (<Input
                    className={'m-0 p-1 rounded-0 ' + (field.className || '')}
                    style={{ textAlign: field.alignment || 'left' }}
                    type={field.type || 'text'}
                    name={field.name}
                    value={displayInputValue(stateVariables?.[props.config]?.[field.name])}
                    onChange={(e) => handleInputChange(field.name, e)}
                    placeholder={field.placeholder || ''}
                />)
        }
    }

    const saveEntry = () => {
        let obj = {};
        if (localStorage.getItem('entries')) {
            obj = JSON.parse(localStorage.getItem('entries'));
        };
        if (!obj[props.config]) obj[props.config] = [];
        stateVariables[props.config]['_Timestamp'] = (new Date().getTime()) / 1000;
        stateVariables[props.config]['_User'] = user;
        stateVariables[props.config]['_Email'] = email;
        obj[props.config].push(Object.entries(stateVariables[props.config]).reduce((acc, [key, val]) => {
            if ((val) || String(key).substring(0, 1) === '_') acc[key] = val;
            return acc;
        }, {}));
        localStorage.setItem('entries', JSON.stringify(obj));
        setEntries(obj);
        initializeStateVariables(true);
    }

    const submitEntries = async () => {
        entries[props.config] && entries[props.config].filter(entry => !entry['_Submitted']).forEach(async entry => {
            const response = await fetch(props.submitLink, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + utilities.getAccessToken()
                },
                body: JSON.stringify(entry)
            })
            if (response.ok) {
                utilities.addToast('Submission successful', 5, 'success');
                const result = await response.json();
                let obj = { ...entries };
                const entryIndex = obj[props.config].findIndex(entry => entry['_TransactionCode'] === result.transactionCode && !entry['_Submitted']);
                if (entryIndex > -1) {
                    obj[props.config][entryIndex]['_Submitted'] = true;
                    setEntries(obj);
                    localStorage.setItem('entries', JSON.stringify(obj));
                }
            } else {
                utilities.addToast('Submission unsuccessful, however the data is being stored on your device and will be uploaded once connected.', 5, 'danger')
            }
        })
    }

    // useEffect can be used for componentDidMount logic.
    useEffect(() => {
        fetchConfig();
    }, []); // empty dependency array ensures the effect runs only once after the initial render

    useEffect((e) => {
        initializeStateVariables();
    }, [props.config]);

    useEffect((e) => {
        localStorage.setItem('user', user)
    }, [user]);

    useEffect((e) => {
        localStorage.setItem('email', email)
    }, [email]);

    useEffect((e) => {
        submitEntries();
    }, [entries]);

    useEffect((e) => {
        let obj = {};
        if (localStorage.getItem('stateVariables')) {
            obj = JSON.parse(localStorage.getItem('stateVariables'));
        };
        obj = { ...obj, ...stateVariables };
        localStorage.setItem('stateVariables', JSON.stringify(obj))
    }, [stateVariables]);



    return (
        <>
            {!isLoading && <div className='tablet p-0 m-0' id="dailyReportContainer">
                {showPreview && <DailyReportPreview
                    hidePreview={() => setShowPreview(false)}
                    {...stateVariables[props.config]}
                    user={user}
                    email={email}
                />}

                {!showPreview && <>
                    <Row className='m-0' id="dailyReportContent">
                        <Col xs={4} className='p-0 bg-secondary'>
                            {/* Left column */}
                            <Row className='m-0 bg-secondary ' >
                                <Col className='p-0 ' style={{ borderTopWidth: '2px', borderRightWidth: 0, borderLeftWidth: 0, borderBottomWidth: 0, borderColor: 'rgb(149,149,149)', borderStyle: 'solid' }}>
                                    {/* Column */}
                                    <div className='dailyReportHeader'>
                                        <Row className='m-0'>
                                            <Col className='p-0'>
                                                {displayInput({ type: 'date', name: `date`, placeholder: 'Date' })}
                                            </Col>
                                            <Col className='p-0'>
                                                {displayInput({ type: 'time', name: `shiftFrom`, placeholder: 'Shift From' })}
                                            </Col>
                                            <Col className='p-0'>
                                                {displayInput({ type: 'time', name: `shiftTo`, placeholder: 'Shift To' })}
                                            </Col>
                                        </Row>
                                        <Row className='m-0'>
                                            <Col className='p-0'>
                                                <Input
                                                    className='rounded-0 p-1 m-0'
                                                    id="user"
                                                    name="user"
                                                    placeholder="User"
                                                    value={user}
                                                    onChange={(e) => setUser(e.target.value)}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className='m-0'>
                                            <Col className='p-0'>
                                                <Input
                                                    className='rounded-0 p-1 m-0'
                                                    id="email"
                                                    name="email"
                                                    placeholder='Email'
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                    <Row className='m-0 text-uppercase text-secondary font-weight-bold lightBlueGrey'>
                                        <Col xs={6}>
                                            Employee Name
                                        </Col>
                                        {/* <Col xs={3}>
                                            Emp No.
                                        </Col> */}
                                        <Col xs={4}>
                                            Class
                                        </Col>
                                        <Col xs={2}>
                                            Rate
                                        </Col>
                                    </Row>
                                    {[...Array(20)].map((_, index) => index + 1).map(row => (
                                        <Row className='m-0' key={row}>
                                            <Col xs={6} className='p-0'>
                                                {displayInput({ type: 'select', name: `employeeName${row}`, optionValues: 'LaborNames' })}
                                            </Col>
                                            {/* <Col xs={2} className='p-0'>
                                                {displayInput({ name: `employeeNumber${row}` })}
                                            </Col> */}
                                            <Col xs={4} className='p-0'>
                                                {displayInput({ type: 'select', name: `class${row}`, optionValues: 'LaborEquipmentOperated' })}
                                            </Col>
                                            <Col xs={2} className='p-0'>
                                                {displayInput({ name: `rate${row}` })}
                                            </Col>
                                        </Row>
                                    ))}
                                </Col>
                            </Row>
                            <Row className='m-0'>
                                <Col className='p-0'>
                                    <Input
                                        type="textarea"
                                        className='rounded-0 p-1 m-0 text-start'
                                        value="Job Equipment Rentals"
                                        disabled={true}
                                    />
                                </Col>
                            </Row>
                            <Row className='m-0'>
                                <Col className='p-0 bg-light' xs={8}>
                                    <Input
                                        type="textarea"
                                        className='rounded-0 p-1 pb-2 m-0 text-start'
                                        value="Job Material Delivered"
                                        disabled={true}
                                        rows={9}
                                    />
                                </Col>
                                <Col className='p-0'>
                                    <Row className='m-0'>
                                        <Col className='p-0'>
                                            <Input
                                                type="textarea"
                                                className='rounded-0 p-1 m-0 text-end'
                                                value="Type"
                                                disabled={true}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className='m-0'>
                                        <Col className='p-0'>
                                            <Input
                                                type="textarea"
                                                className='rounded-0 p-1 m-0 text-end'
                                                value="Quantity"
                                                disabled={true}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className='m-0'>
                                        <Col className='p-0'>
                                            <Input
                                                type="textarea"
                                                className='rounded-0 p-1 m-0 text-end'
                                                value="From"
                                                disabled={true}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className='m-0'>
                                        <Col className='p-0'>
                                            <Input
                                                type="textarea"
                                                className='rounded-0 p-1 m-0 text-end'
                                                value="Hauled By"
                                                disabled={true}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={8} className='p-0 ps-1 bg-secondary'>
                            {/* Scrolling jobs */}
                            <div style={{ overflowX: 'scroll' }}>
                                <div style={{ width: '2400px' }}>
                                    <Row className='m-0 border border-secondary bg-secondary p-0'>
                                        {[1, 2, 3, 4].map(col => (
                                            <Col xs={3} className='py-0 ps-0 pe-1 border border-secondary' key={col}>
                                                {/* Column */}
                                                <div className='dailyReportHeader'>
                                                    <Row className='m-0'>
                                                        <Col className='p-0'>
                                                            {displayInput({ name: `job${col}_job`, placeholder: `Job ${col}` })}
                                                        </Col>
                                                    </Row>
                                                    <Row className='m-0'>
                                                        <Col className='p-0'>
                                                            {displayInput({ name: `job${col}_rfco`, placeholder: 'RFCO' })}
                                                        </Col>
                                                    </Row>
                                                    <Row className='m-0'>
                                                        <Col className='p-0'>
                                                            {displayInput({ name: `job${col}_jobNumber`, placeholder: 'Job No.' })}
                                                        </Col>
                                                        <Col className='p-0'>
                                                            {displayInput({ name: `job${col}_codeNumber`, placeholder: 'Code No.' })}
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <Row className='m-0 text-uppercase text-secondary font-weight-bold lightBlueGrey'>
                                                    <Col xs={2}>
                                                        ST Hrs
                                                    </Col>
                                                    <Col xs={2}>
                                                        OT Hrs
                                                    </Col>
                                                    <Col xs={2}>
                                                        DBL Hrs
                                                    </Col>
                                                    <Col xs={4}>
                                                        Equip No
                                                    </Col>
                                                    <Col xs={2}>
                                                        Equip Hrs
                                                    </Col>
                                                </Row>
                                                {[...Array(20)].map((_, index) => index + 1).map(row => (
                                                    <Row className='m-0' key={row}>
                                                        <Col xs={2} className='p-0'>
                                                            {displayInput({ type: 'number', name: `job${col}_standardHours${row}` })}
                                                        </Col>
                                                        <Col xs={2} className='p-0'>
                                                            {displayInput({ type: 'number', name: `job${col}_overtimeHours${row}` })}
                                                        </Col>
                                                        <Col xs={2} className='p-0'>
                                                            {displayInput({ type: 'number', name: `job${col}_doubleHours${row}` })}
                                                        </Col>
                                                        <Col xs={4} className='p-0'>
                                                            {displayInput({ type: 'select', name: `job${col}_equipmentNumber${row}`, className: 'lightBlueGrey', optionValues: 'LaborEquipmentNumbers' })}
                                                        </Col>
                                                        <Col xs={2} className='p-0'>
                                                            {displayInput({ type: 'number', name: `job${col}_equipmentHours${row}`, className: 'lightBlueGrey' })}
                                                        </Col>
                                                    </Row>
                                                ))}
                                                <Row className='m-0'>
                                                    <Col className='p-0'>
                                                        {displayInput({ type: 'textarea', name: `job${col}_equipmentRentals`, placeholder: `Job ${col} Equipment Rentals` })}
                                                    </Col>
                                                </Row>
                                                <Row className='m-0'>
                                                    <Col className='p-0'>
                                                        {displayInput({ type: 'textarea', name: `job${col}_materialDeliveredToJobType`, placeholder: `Job ${col} Material Delivered - Type` })}
                                                    </Col>
                                                </Row>
                                                <Row className='m-0'>
                                                    <Col className='p-0'>
                                                        {displayInput({ type: 'textarea', name: `job${col}_materialDeliveredToJobQuantity`, placeholder: `Job ${col} Material Delivered - Quantity` })}
                                                    </Col>
                                                </Row>
                                                <Row className='m-0'>
                                                    <Col className='p-0'>
                                                        {displayInput({ type: 'textarea', name: `job${col}_materialDeliveredToJobFrom`, placeholder: `Job ${col} Material Delivered - From` })}
                                                    </Col>
                                                </Row>
                                                <Row className='m-0'>
                                                    <Col className='p-0'>
                                                        {displayInput({ type: 'textarea', name: `job${col}_materialDeliveredToJobHauledBy`, placeholder: `Job ${col} Material Delivered - Hauled By` })}
                                                    </Col>
                                                </Row>
                                            </Col>
                                        ))}
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='m-0 border border-secondary bg-secondary' id="dailyReportFooter">
                        <Col sm={4} className='p-0 border border-secondary bg-white'>
                            {stateVariables[props.config]?.foremanSignature &&
                                <img className="signatureImage" src={stateVariables[props.config]?.foremanSignature} alt="Foreman Signature" />}

                            <CanvasModal
                                arrkey="foremanSignature"
                                saveimage={setStateVariables}
                                config={props.config}
                            >
                                <Input
                                    className='px-1 m-0 border-0 rounded-0 w-100 bg-transparent'
                                    id="foremanSignature"
                                    name="foremanSignature"
                                    readOnly={true}
                                    style={{ width: 300, paddingBottom: '90px !important' }}
                                    placeholder='Authorized By'
                                />
                            </CanvasModal>
                        </Col>
                        <Col className='p-0 ps-1 border border-secondary'>
                            {displayInput({ type: 'textarea', name: `comments`, placeholder: 'Comments', rows: 3 })}
                        </Col>
                    </Row>
                </>
                }
            </div >}
        </>
    );
};

export default DailyReport;