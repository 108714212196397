import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';

import Home from './pages/Home';
import * as utilities from './utilities';
import Nav from './components/Nav/Nav';
import NavProvider from './components/Nav/NavProvider.js';
import ToastPanel from './components/Toasts/ToastPanel';
import Config from './pages/Config';
import SafetyApp from './pages/SafetyApp.js';
import SafetyAppEntries from './pages/SafetyAppEntries';
import DailyReport from './pages/DailyReport';
import DailyReportEntries from './pages/DailyReportEntries';
import Permissions from './pages/Permissions';
import Users from './pages/Users';

const App = () => {
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const history = useNavigate();

  const loginUser = (obj) => {
    localStorage.setItem('accessToken', obj.accessToken);
    localStorage.setItem('sessionGUID', obj.sessionGUID);
    localStorage.setItem('endTimestamp', new Date(obj.endTimestamp + "Z").getTime());
    localStorage.setItem('username', obj.username);
    localStorage.setItem('user', `${obj.firstName} ${obj.lastName}`);
    localStorage.setItem('email', obj.username);
    localStorage.setItem('permissions', JSON.stringify(obj.permissions));
    localStorage.setItem('userObj', JSON.stringify(obj));
    setUserLinks(links.filter((link) => utilities.checkPermission(link.permissionGUID)));
  }

  useEffect(() => {
    if (isLoggingOut) {
      history('/');
      setIsLoggingOut(false);
    }
  }, [isLoggingOut]);

  const handleHome = () => {
    history('/');
  };

  const logout = async () => {
    const sessionGUID = utilities.getSessionGUID();
    const rememberUsername = localStorage.getItem('rememberUsername') != null ? localStorage.getItem('rememberUsername') : null;
    localStorage.clear()
    if (rememberUsername != null) {
      localStorage.setItem('rememberUsername', rememberUsername);
    }
    setIsLoggingOut(true);
    fetch('/app/logout/' + sessionGUID, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + utilities.getAccessToken()
      }
    })
  }

  const links = [
    {
      url: '/asta/daily-report',
      link: 'ASTA Construction Daily Report',
      authenticated: true,
      permissionGUID: '8928fcf6-d902-4773-897e-bfa77e371c73',
      exact: true,
      module: <DailyReport
        config="config-asta-daily-report"
        configLink="/api/daily-report/configuration/asta"
        submitLink="/api/daily-report/submit/asta"
      />,
    },
    {
      url: '/asta/daily-report/admin',
      link: 'ASTA Construction Daily Report Submissions',
      authenticated: true,
      permissionGUID: '88467808-5d50-494c-9884-d510c95dff79',
      exact: true,
      small: true,
      module: <DailyReportEntries
        entriesLink="/api/daily-report/entries/asta"
        updateLink="/api/daily-report/entries/"
      />,
    },
    {
      url: '/asta/daily-report/config',
      link: 'ASTA Construction Daily Report Configuration',
      authenticated: true,
      permissionGUID: '845792ac-9f1a-4faa-b4c2-3b2da67d2069',
      exact: true,
      small: true,
      module: <Config
        configLink="/api/daily-report/configuration/asta"
        submitLink="/api/daily-report/submit/asta"
      />,
    },
    {
      url: '/asta/safety-app',
      link: 'ASTA Construction Safety App',
      authenticated: true,
      permissionGUID: 'bd375527-aee4-4456-afe5-dcd0ac844585',
      exact: true,
      module: <SafetyApp
        config="config-asta-safety-app"
        configLink="/api/safety-app/configuration/asta"
        submitLink="/api/safety-app/submit/asta"
      />,
    },
    {
      url: '/asta/safety-app/admin',
      link: 'ASTA Construction Safety App Submissions',
      authenticated: true,
      permissionGUID: '28318dc8-b0d8-46c6-b9b9-12350ea14509',
      exact: true,
      small: true,
      module: <SafetyAppEntries
        entriesLink="/api/safety-app/entries/asta"
        updateLink="/api/safety-app/entries/"
      />,
    },
    {
      url: '/admin/users',
      link: 'Users',
      authenticated: true,
      permissionGUID: '841fa389-0fcd-47ac-929f-c0c075b02cba',
      exact: true,
      module: <Users />,
    },
    {
      url: '/admin/permissions',
      link: 'Permissions',
      authenticated: true,
      permissionGUID: 'ab869767-c9e4-405c-b33e-c52019c7b733',
      exact: true,
      module: <Permissions />,
    },
  ];

  const [userLinks, setUserLinks] = useState(links.filter((link) => utilities.checkPermission(link.permissionGUID)));

  return (
    <NavProvider>
      <div className="container-fluid d-flex flex-column vh-100 p-0">
        <header className="fixed-top appHeader" id="appHeader">
          <Nav
            links={userLinks}
            logout={logout}
            handleHome={handleHome}
          />
        </header>
        <div className="mt-0" id="appContent">
          <Routes>
            <Route
              Component={() => <Home
                links={userLinks}
                loginUser={loginUser}
                logout={logout}
              />}
              exact={true}
              path="/"
            />
            {userLinks
              .map((link) => (
                <Route
                  key={link.url}
                  exact={link.exact}
                  Component={() => link.module}
                  path={`${link.url}`}
                />
              ))}
          </Routes>
          <div className="toastPanel align-bottom">
            <ToastPanel addCallback={utilities.addCallback} log={utilities.log} info={utilities.info} />
          </div>
        </div>
      </div>
    </NavProvider >
  );
};

export default App;
