import React, { useState } from 'react';
import NavContext from './NavContext';

const NavProvider = ({ children }) => {
    const [navContent, setNavContent] = useState(null);

    return (
        <NavContext.Provider value={{ navContent, setNavContent }}>
            {children}
        </NavContext.Provider>
    );
};

export default NavProvider;
