import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';

const ConfirmButton = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [action, setAction] = useState(() => { });

  useEffect(() => {
    if (props.onClick != null && typeof props.onClick === 'function') {
      setAction(() => props.onClick);
    }
  }, [props.onClick]);

  const showModal = (isOpen) => {
    setIsOpen(isOpen);
  };

  const executeAction = () => {
    showModal(false);
    if (typeof action === 'function') {
      action();
    }
  };

  return (
    <>
      <Button {...props} onClick={() => setIsOpen(true)}>
        {props.children}
      </Button>
      <Modal centered isOpen={isOpen}>
        <ModalBody className='text-left'>
          {props.body != null ? props.body : props.message != null ? props.message : 'Are you sure you wish to proceed?'}
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={executeAction}
          >
            Yes
          </Button>
          <Button onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ConfirmButton;
