import React, { useEffect, useState, useContext } from 'react';
import { Container, Row, Col, Input, Button, Label, Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faDownload } from '@fortawesome/free-solid-svg-icons';
import ConfirmButton from '../components/ConfirmButton/ConfirmButton';
import SafetyAppPreviewEdit from '../components/Preview/SafetyAppPreviewEdit';
import NavContext from '../components/Nav/NavContext';
import * as utilities from '../utilities';

const SafetyAppEntries = (props) => {

  const [entries, setEntries] = useState([]);
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');
  const [search, setSearch] = useState('');
  const [showPreview, setShowPreview] = useState(false);
  const [viewEntry, setViewEntry] = useState({});

  const { setNavContent } = useContext(NavContext);

  useEffect(() => {
    setNavContent(
      <h2 className='text-end text-light'>Safety App</h2>
    );
    return () => setNavContent(null);
  }, [setNavContent]);

  const getEntries = async () => {
    const response = await fetch(props.entriesLink, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + utilities.getAccessToken()
      },
      body: JSON.stringify({
        start: start,
        end: end,
        search: search
      })
    })

    if (response.ok) {
      const result = await response.json();
      setEntries((result.entries));
    } else {
      utilities.addToast('Unable to load submissions', 5, 'danger');
    }
  };

  const mergeEntries = (updatedEntry) => {
    const tempObj = [...entries];
    const entryIndex = tempObj.findIndex(entry => entry.entryId === updatedEntry.entryId);
    if (entryIndex === -1) return;
    tempObj[entryIndex] = updatedEntry;
    setEntries(tempObj);
  }

  const deleteEntry = (entryId) => {
    const tempObj = [...entries];
    const entryIndex = tempObj.findIndex(entry => entry.entryId === entryId);
    if (entryIndex === -1) return;
    tempObj.splice(entryIndex, 1);
    setEntries(tempObj);
  }

  const onEdit = async (id, value, prop) => {
    const response = await fetch(props.updateLink + id, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + utilities.getAccessToken()
      },
      body: JSON.stringify({
        [prop]: value ? 0 : 1,
      })
    })

    if (response.ok) {
      const result = await response.json();
      if (result.data && result.data.length > 0) mergeEntries(result.data[0]);
    }
  }

  const onDelete = async (id) => {
    const response = await fetch(props.updateLink + id, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + utilities.getAccessToken()
      }
    })

    if (response.ok) {
      utilities.addToast('Submission deleted', 5, 'success');
      deleteEntry(id);
    } else {
      utilities.addToast('Unable to delete submission', 5, 'danger');
    }

  }

  const onUpdate = async (id, value, cb) => {
    const response = await fetch(props.updateLink + id, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + utilities.getAccessToken()
      },
      body: JSON.stringify({
        ...value
      })
    })

    if (response.ok) {
      const result = await response.json();
      if (result.data && result.data.length > 0) {
        setShowPreview(false);
        utilities.addToast('Update successful', 5, 'success');
        mergeEntries(result.data[0]);
      }
    } else {
      utilities.addToast('Submission unsuccessful', 5, 'danger');
    }
  }

  const localDate = (date) => {
    return date.toLocaleString('en-US', {
      month: 'numeric',
      day: 'numeric',
      year: '2-digit',
      // hour: 'numeric',
      // minute: 'numeric',
      // hour12: true,
      // timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
  }

  const localDateTime = (date) => {
    return date.toLocaleString('en-US', {
      month: 'numeric',
      day: 'numeric',
      year: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
  }

  useEffect(() => {
    getEntries();
  }, []);

  return (
    <Container fluid className='tablet p-0 m-0'>
      {showPreview && viewEntry.data && <SafetyAppPreviewEdit
        hidePreview={() => setShowPreview(false)}
        onUpdate={onUpdate}
        entryId={viewEntry.entryId}
        user={viewEntry.user}
        email={viewEntry.email}
        entry={viewEntry.data}
      />}
      {!showPreview &&
        <>
          <Row className='mt-3 mx-0 row-cols-lg-auto g-3 align-items-end'>
            <Col>
              <Label>
                Start
                <Input
                  name="start"
                  type="date"
                  value={start}
                  onChange={(e) => setStart(e.target.value)}
                >
                </Input>
              </Label>
            </Col>
            <Col>
              <Label>
                End
                <Input
                  name="end"
                  type="date"
                  value={end}
                  onChange={(e) => setEnd(e.target.value)}
                >
                </Input>
              </Label>
            </Col>
            <Col>
              <Label>
                Search
                <Input
                  name="search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                >
                </Input>
              </Label>
            </Col>
            <Col>
              <Button color="secondary" onClick={() => {
                setStart('');
                setEnd('');
                setSearch('');
              }}>
                Clear
              </Button>
            </Col>
            <Col>
              <Button color="primary" onClick={getEntries}>
                Submit
              </Button>
            </Col>
          </Row>
          <Row className='mt-5'>
            <Col>
              <Table className='entries table-white'>
                <thead>
                  <tr>
                    <th width="10%" className="downloadedColumn">&nbsp;</th>
                    <th width="10%" >&nbsp;</th>
                    <th width="25%" >Job No.</th>
                    <th width="10%">Date</th>
                    <th width="15%" >Submitted</th>
                    <th width="20%" >Foreman</th>
                    <th width="10%" className="deleteColumn">&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {entries.map(entry => (
                    <tr key={entry.entryId}>
                      <td>
                        <Button
                          onClick={() => onEdit(entry.entryId, entry.reportDownloaded === 1, '_ReportDownloaded')}
                          className='faButton'
                          outline={entry.reportDownloaded === 0}
                          color={entry.reportDownloaded === 0 ? 'secondary' : 'success'}
                          title="Downloaded"
                        >
                          <FontAwesomeIcon icon={faDownload} />
                        </Button>
                      </td>
                      <td><Button color="link" onClick={() => {
                        setViewEntry(entry)
                        setShowPreview(true);
                      }}>
                        View
                      </Button></td>
                      <td>{entry.data.jobNumber != null ? entry.data.jobNumber : '--'}</td>
                      <td>{entry.data.date != null ? localDate(new Date(entry.data.date + 'T00:00')) : '--'}</td>
                      <td>{entry.timestamp != null ? localDateTime(new Date(entry.timestamp * 1000)) : '--'}</td>
                      <td><a className='text-primary fw-bold text-decoration-none' href={`mailto:${entry.email}`}>{entry.user}</a></td>
                      <td>
                        <ConfirmButton
                          onClick={() => onDelete(entry.entryId)}
                          className='faButton'
                          color="danger"
                          title="Delete"
                        >
                          <FontAwesomeIcon icon={faTimes} />
                        </ConfirmButton>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </>}
    </Container>
  );
};

export default SafetyAppEntries;
