let toastCallback = false;

export function checkPermission(permissionGUID) {
    if (permissionGUID == null) {
        return true;
    }
    if (localStorage.getItem('permissions') == null) {
        return false;
    } else {
        const userPermissions = JSON.parse(localStorage.getItem('permissions'));
        if (typeof (permissionGUID) !== 'object') permissionGUID = [permissionGUID];
        let allowed = false;
        permissionGUID.forEach(permission => {
            if (userPermissions.findIndex(userPermission => userPermission.permissionGUID === permission || userPermission.permissionName === permission) > -1) {
                allowed = true;
            }
        })
        return allowed;
    }
}

export function getAccessToken() {
    return localStorage.getItem('accessToken');
}

export function getSessionGUID() {
    return localStorage.getItem('sessionGUID');
}

export function getSessionEndTimestamp() {
    return parseInt(localStorage.getItem('sessionEndTimestamp'));
}

export function getTeamMemberUsername() {
    return localStorage.getItem('teamMemberUsername');
}

export function isAuthenticated() {
    return localStorage.getItem('accessToken') != null;
}

export function checkAuthenticated(needsAuthentication) {
    if (needsAuthentication == null) {
        return true;
    }
    if (needsAuthentication && localStorage.getItem('accessToken') != null) {
        return true;
    } else if (!needsAuthentication && localStorage.getItem('accessToken') == null) {
        return true;
    } else {
        return false;
    }
}

export function log(...args) {
    const colors = ['#BADA55', '#F5B7B1', '#F5CBA7', '#F9E79F', '#ABEBC6', '#A3E4D7', '#AED6F1', '#D7BDE2']
    const method = args.shift();
    const page = method.split('/')[0];
    let color = '';
    switch (page) {
        // case "Home.js": color = colors[3]; break;
        default: color = colors[0];
    }
    if (parseInt(process.env.REACT_APP_DEBUG) >= 1) console.log('%c' + method + '\n', 'font-weight: bold; color: ' + color + ';', ...args)
}

export function info(...args) {
    const colors = ['#888888', '#F5CBA7', '#F9E79F', '#ABEBC6', '#A3E4D7', '#AED6F1', '#D7BDE2']
    const method = args.shift();
    const page = method.split('/')[0];
    let color = '';
    switch (page) {
        // case "Home.js": color = colors[3]; break;
        default: color = colors[0];
    }
    if (parseInt(process.env.REACT_APP_DEBUG) >= 2) console.log('%c' + method + '\n', 'font-weight: bold; color: ' + color + ';', ...args)
}

export function addToast(str, delay, color) {
    log('utilities.js/addToast (str,delay,color)', str, delay, color)
    if (toastCallback != null && typeof (toastCallback) === 'function') toastCallback({
        str: str,
        delay: (delay != null ? delay : 5),
        color: (color != null ? color : 'primary'),
        timestamp: new Date()
    })
}

export function addCallback(cb) {
    log('utilities.js/addCallback (cb)', cb)
    toastCallback = cb;
}

export function removeCallback() {
    log('utilities.js/removeCallback (cb)')
    toastCallback = null;
}

export function isJSON(str) {
    log('utilities.js/isJSON (str)', str)
    if (str === '' || str == null || str === undefined || str.length < 2) return false;
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export function isFieldNull(val, checkbox) {
    info('utilities.js/isFieldNull (val, checkbox)', val, checkbox);
    return (val != null) ? val : ((checkbox != null && checkbox) ? false : '');
}